<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import axios from 'axios'
import ToHold from './components/ToHold.vue'
import ToPay from './components/ToPay.vue'
import EditLastPay from './components/EditLastPay.vue'
import AddReason from './components/AddReason.vue'

import AgGrid from '@/components/custom/AgGrid.vue'
import { formattedAmount } from '@/assets/js/calculator'
import { formattedDate, getDateDiff, reverseDateFormatter } from '@/assets/js/dateFunction'

export default {
  name: 'CollectionProfile',
  components: {
    // TransactionDiv
    AgGrid,
    ToHold,
    ToPay,
    EditLastPay,
    AddReason
  },
  data() {
    return {
      reschedule: null,
      clickedTransactionId: null,
      prepaymentEnum: {
        zero: '0 + 0',
        one: '1 + 0',
        two: '1 + 1',
        three: '1 + 2'
      },
      applicationProduct: {},
      allInstallments: [],
      settledInstallments: [],
      paymentHistory: [],
      paymentHistoryKey: [
        {
          field: 'paidAt',
          headerName: 'Paid At',
          filter: 'agDateColumnFilter',
          cellDataType: 'date'
        },
        { field: 'amount', headerName: 'Amount', cellDataType: 'currency', currency: 'RM' },
        { field: 'CreatedUser.name', headerName: 'Created By' },
        { field: 'UpdatedUser.name', headerName: 'Updated By' }
        // {
        //   field: 'UpdatedUser.name',
        //   headerName: 'Updated At',
        //   filter: 'agDateColumnFilter',
        //   cellDataType: 'date'
        // }
      ],
      installmentsKey: [
        { field: 'description', fieldName: 'Description' },
        { field: 'dueDate', fieldName: 'Due Date' },
        {
          field: 'chargeAmount',
          fieldName: 'Charge Amount',
          cellDataType: 'currency',
          currency: 'RM'
        },
        { field: 'paidAmount', fieldName: 'Paid', cellDataType: 'currency', currency: 'RM' },
        { field: 'paidAt', fieldName: 'Paid At' },
        { field: 'status', fieldName: 'Status', cellDataType: 'installmentStatus' },
        {
          field: 'peerformanceStatus',
          fieldName: 'Performance',
          cellDataType: 'installmentPerformance'
        },
        { field: 'note', fieldName: 'Reason', cellDataType: 'installmentReason' }
      ]
    }
  },

  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader']),
    ...mapState(['apiUrl']),
    prepaymentPlan() {
      if (this.applicationProduct?.prepayment == null) return 'N/A'
      return this.getPrepaymentPlan(this.applicationProduct?.prepayment)
    },
    prepaymentInstallments() {
      if (this.applicationProduct?.prepayment == null) return 'N/A'
      let arr
      const enumKey = Object.keys(this.prepaymentEnum)
      let prepaymentKey

      enumKey.forEach((key) => {
        if (this.prepaymentEnum[key] == this.prepaymentPlan) {
          prepaymentKey = key
        }
      })

      if (prepaymentKey == 'zero') {
        arr = this.calPrepaymentInstallments('zero')
      }

      if (prepaymentKey == 'one') {
        arr = this.calPrepaymentInstallments('one')
      }

      if (prepaymentKey == 'two') {
        arr = this.calPrepaymentInstallments('two')
      }

      if (prepaymentKey == 'three') {
        arr = this.calPrepaymentInstallments('three')
      }

      console.log(arr, 'the arr')
      return arr
    },
    approvalList() {
      if (this.reschedule == null) return 'N/A'
      return JSON.parse(this.reschedule.approvalList)
    }
  },

  watch: {
    prepaymentInstallments: {
      handler() {
        if (this.applicationProduct == null) return 'N/A'
        if (this.paymentHistory.length == 0) return 'N/A'
        this.settledInstallments = []
        const filteredInstallments = this.applicationProduct.ApplicantProductTransactions.filter(
          (x) => x.status != 'Pending'
        )

        // this.settledInstallments.unshift(installment)
        this.settledInstallments.unshift(...filteredInstallments.reverse())
      },
      deep: true
    }
  },
  created() {
    this.apiHeader = this.getAPIHeader()
    this.fetchData()
  },
  // beforeMount() {

  // },
  mounted() {},
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),

    formattedDate,
    formattedAmount,
    reverseDateFormatter,
    refreshPage() {
      window.location.reload()
    },
    async approveReschedule() {
      const { data } = await axios.put(
        `${this.apiUrl}/applicantProductReschedule/approve`,
        { id: this.reschedule.id },
        {
          headers: this.apiHeader
        }
      )
      // console.log(data)
      data
      window.location.reload()
    },
    async rejectReschedule() {
      const { data } = await axios.put(
        `${this.apiUrl}/applicantProductReschedule/reject`,
        { id: this.reschedule.id },
        {
          headers: this.apiHeader
        }
      )
      // console.log(data)
      data
      window.location.reload()
    },
    installmentsTableClicked(row, e) {
      // console.log(e.event.srcElement)
      const ele = e.event.srcElement
      if (ele.dataset.purpose == 'addReason') {
        const myModal = new this.$store.state.bootstrap.Modal('#AddReasonModal', {
          keyboard: false
        })
        this.clickedTransactionId = ele.dataset.id
        myModal.show()
      }

      // console.log(e.event.srcElement)
      // console.log(this.$refs.AddReasonModal, document.querySelector('#AddReasonModal'))
      // document.querySelector('#AddReasonModal').modal('show')
    },

    getResourceFileName(targetId) {
      const result = this.applicantDocumentTypes.find((documentType) => documentType.id == targetId)
      return result?.name
    },
    getResourceName(id) {
      if (!id) {
        return null
      }
      return this.resources.find((x) => x.id == id)?.name
    },

    async fetchData() {
      this.increaseIsFetching()
      try {
        const { data } = await axios.get(
          `${this.apiUrl}/applicantProduct/details/${this.$route.params.id}`,
          {
            headers: this.apiHeader
          }
        )
        // ApplicantProductReschedules
        this.reschedule = data.ApplicantProduct.ApplicantProductReschedules[0]
        this.applicationProduct = data.ApplicantProduct
        this.allInstallments = data.ApplicantProduct.ApplicantProductTransactions.map((x) => ({
          ...x,
          dueDate: formattedDate(x.dueDate)
        }))
        this.paymentHistory = this.applicationProduct?.ApplicantProductPayments.map((x) => ({
          ...x,
          paidAt: formattedDate(x.paidAt)
        }))

        this.decreaseIsFetching()
      } catch (error) {
        console.log(error)
        this.decreaseIsFetching()
      }
    },
    getpaymentStatus(endDate, startDate) {
      if (startDate) {
        const dayDiffs = getDateDiff(endDate, startDate)
        if (dayDiffs) {
          return dayDiffs > 7 ? 'Prompt' : dayDiffs < -7 ? 'Late' : 'Normal'
        } else {
          return 'Normal'
        }
      } else {
        return 'Pending'
      }
    },
    getPrepaymentPlan(prepayment) {
      const arr = JSON.parse(prepayment)
      let prepaymentKey
      // const

      if (arr.length == 0) {
        prepaymentKey = 'zero'
      }

      // 0 + 0
      if (arr[0] == 0 && arr[1] == 0) {
        prepaymentKey = 'zero'
      }

      // 1 + 0
      if (arr[0] == 1 && arr[1] == 0) {
        prepaymentKey = 'one'
      }

      // 0 + 1
      if (arr[0] == 0 && arr[1] == 1) {
        prepaymentKey = 'one'
      }

      // 1 + 1
      if (arr[0] == 1 && arr[1] == 1) {
        prepaymentKey = 'two'
      }

      // 0 + 2
      if (arr[0] == 0 && arr[1] == 2) {
        prepaymentKey = 'two'
      }

      // 1 + 2
      if (arr[0] == 1 && arr[1] == 2) {
        prepaymentKey = 'three'
      }

      return this.prepaymentEnum[prepaymentKey]
    },
    calPrepaymentInstallments(type) {
      let ppMonths
      let arr = []
      if (type == 'zero') ppMonths = 0
      if (type == 'one') ppMonths = 1
      if (type == 'two') ppMonths = 2
      if (type == 'three') ppMonths = 3
      const monthlyInstallment = this.applicationProduct.monthlyInstallment

      for (let i = 0; i < ppMonths; i++) {
        arr.push({
          desc: 'Pre-Payment',
          amount: monthlyInstallment,
          paid: false,
          paidAt: null,
          status: null,
          performance: null
        })
      }
      return arr
    },

    triggerTest(params) {
      return params
    }
  }
}
</script>
<template>
  <div class="py-4 container-fluid pt-0">
    <div class="card">
      <div class="card-header d-flex">
        <h5>
          {{ applicationProduct.hpNo }}

          <span v-if="applicationProduct.outstandingBalance == 0" class="badge badge-success"
            >Settled</span
          >
          <span
            v-else-if="reschedule && reschedule.status == 'Approved'"
            class="badge badge-warning"
            >Rescheduled</span
          >
          <span v-else class="badge badge-info">Ongoing</span>
        </h5>
      </div>
      <div class="card-body">
        <div v-if="applicationProduct.Hirer" id="customerInfo" class="grid-auto">
          <div class="wrapper">
            <h6 class="w-50">Customer</h6>
            <router-link
              :to="{
                name: 'CompanyProfile',
                params: { id: applicationProduct.Hirer.id }
              }"
              >{{ applicationProduct?.Hirer?.name ?? 'N/A' }}
              <p class="text-secondary">
                ( {{ applicationProduct.Hirer?.registrationNo ?? 'N/A' }} )
              </p></router-link
            >
          </div>

          <!-- <div class="wrapper">
            <h6 class="w-50">Agreement No.</h6>
            <p>{{ applicationProduct.hpNo ?? 'N/A' }}</p>
          </div> -->

          <div class="wrapper">
            <h6>Total Amount Payable</h6>
            <p>RM {{ formattedAmount(applicationProduct.totalAmountPayable) ?? 'N/A' }}</p>
          </div>

          <div class="wrapper">
            <h6>Loan Amount</h6>
            <p>RM {{ formattedAmount(applicationProduct.loanAmount) ?? 'N/A' }}</p>
          </div>

          <div class="wrapper">
            <h6>Rate</h6>
            <p>{{ applicationProduct.interestRate ?? 'N/A' }} %</p>
          </div>

          <div class="wrapper">
            <h6>Installment</h6>
            <p>RM {{ formattedAmount(applicationProduct.monthlyInstallment) ?? 'N/A' }}</p>
          </div>

          <div class="wrapper">
            <h6>Outstanding Balance</h6>
            <p>RM {{ formattedAmount(applicationProduct.outstandingBalance) ?? 'N/A' }}</p>
          </div>

          <div class="wrapper">
            <h6>Paid Amount</h6>
            <p>RM {{ formattedAmount(applicationProduct.totalPaid) ?? 'N/A' }}</p>
          </div>

          <div class="wrapper">
            <h6>Pre-Payment Type</h6>
            <p>{{ prepaymentPlan ?? 'N/A' }}</p>
          </div>

          <!-- {{ prepaymentInstallments }} -->
        </div>
      </div>
      <div class="card-footer">
        <div class="flex-row flex-end">
          <button
            class="btn btn-submain text-white"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#holdModal"
            :disabled="
              reschedule?.status == 'Pending' || applicationProduct.outstandingBalance == 0
            "
          >
            Reschedule
          </button>
        </div>

        <ToHold
          @invalidateData="fetchData"
          :installments="this.applicationProduct.ApplicantProductTransactions"
        />
      </div>
    </div>

    <div
      class="card"
      style="margin-top: 3rem"
      v-if="reschedule && reschedule?.status != 'Rejected'"
    >
      <div class="card-header d-flex">
        <h5>Approval Reschedule</h5>
      </div>
      <div class="card-body">
        <div class="grid-auto">
          <div class="wrapper">
            <h6>Status</h6>
            <p>{{ reschedule?.status }} ({{ approvalList.length }} / 2)</p>
            <p v-for="each in approvalList" :key="each">
              {{ each.submittedBy }} - {{ each.status }} - {{ each.submittedAt }}
            </p>
          </div>
          <div class="wrapper">
            <h6>Months</h6>
            <p>{{ reschedule?.month }}</p>
          </div>
          <div class="wrapper">
            <h6>Submitted On</h6>
            <p>{{ reschedule?.createdAt }}</p>
          </div>
          <!-- createdBy -->
          <div class="wrapper">
            <h6>Submitted By</h6>
            <p>{{ reschedule?.createdBy }}</p>
          </div>
        </div>
        <div class="flex-row flex-end gap-s">
          <button
            class="btn btn-submain text-white"
            type="button"
            :disabled="
              $store.state.user.Role.name != 'Superadmin' ||
              approvalList.map((x) => x.submittedBy).includes($store.state.user.name)
            "
            @click="approveReschedule"
          >
            Approve
          </button>

          <button
            class="btn btn-danger text-white"
            type="button"
            :disabled="
              $store.state.user.Role.name != 'Superadmin' ||
              approvalList.map((x) => x.submittedBy).includes($store.state.user.name)
            "
            @click="rejectReschedule"
          >
            Reject
          </button>
          <!-- {{ $store.state.user.name }}
          {{ $store.state.user.Role.name }} -->
        </div>
      </div>
    </div>

    <div class="page-main-content" style="margin-top: 3rem">
      <div>
        <h5>Installments</h5>

        <div class="flex-row space-between">
          <button
            class="btn btn-submain text-white"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#payModal"
          >
            Make Payment
          </button>
          <button
            class="btn btn-submain text-white"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#editLastPayment"
            :disabled="paymentHistory.length == 0"
          >
            Edit Latest Payment
          </button>
        </div>
        <ToPay :lastPayment="paymentHistory[0]" @invalidateData="refreshPage" />
        <EditLastPay :lastPayment="paymentHistory[0]" @invalidateData="refreshPage" />
        <AddReason
          :applicantProductTransactionId="clickedTransactionId"
          @invalidateData="refreshPage"
        />
        <AgGrid
          :data="settledInstallments"
          :keys="installmentsKey"
          :searchable="false"
          :exportable="false"
          @onRowClicked="installmentsTableClicked"
        ></AgGrid>
      </div>
    </div>

    <div style="margin-top: 3rem">
      <h5>Transaction Records</h5>

      <AgGrid
        :data="paymentHistory"
        :keys="paymentHistoryKey"
        :searchable="false"
        :exportable="false"
        @onRowClicked="triggerTest"
      ></AgGrid>
    </div>
  </div>
</template>

<style>
.collapse-container {
  cursor: pointer;
  border-radius: 20px;
}
.grid-auto {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  gap: 3rem;
}

.grid-auto > .wrapper {
  width: fit-content;
}

.grid-auto > .wrapper > h6 {
  white-space: nowrap;
  display: block;
}

.flex-end {
  justify-content: flex-end;
}

.badge-yellow {
  color: hsl(209, 100%, 70%) !important;
  background-color: #fcff49;
}

.gap-s {
  gap: 1rem;
}
</style>
