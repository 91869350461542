<script>
import AgGrid from '@/components/custom/AgGrid'
import { mapState, mapMutations, mapGetters } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import {
  formattedAmount,
  calTotalInterestRate,
  calTotalPaylable,
  undoFormatCurrency
} from '@/assets/js/calculator'
import { getUniqueIndex } from '@/assets/js/general-func'
// import Datepicker from '@vuepic/vue-datepicker'
import qs from 'qs'

export default {
  name: 'ApplicationList',
  components: {
    // Datepicker,
    AgGrid
  },
  data() {
    return {
      totalPages: 1,
      currentPage: 1,
      maxPageLinks: 10,
      masterList: [],
      filteredProducts: [],
      keys: [
        {
          headerName: '#',
          valueGetter: getUniqueIndex,
          pinned: 'left',
          headerCheckboxSelection: false,
          checkboxSelection: false,
          showDisabledCheckboxes: false,
          width: '5%'
        },
        {
          field: 'createdAt',
          headerName: 'Date',
          filter: 'agDateColumnFilter',
          cellDataType: 'date'
          // filterParams: {
          //   // Provide custom comparator for date filtering
          //   comparator: (selectedDate, dateString) => {
          //     const [day, month, year] = dateString.split('-')
          //     const date = new Date(year, month - 1, day)

          //     if (date.getTime() < selectedDate.getTime()) {
          //       return -1
          //     }
          //     if (date.getTime() > selectedDate.getTime()) {
          //       return 1
          //     }
          //     return 0
          //   }
          // }
        },
        { field: 'hirerName', headerName: 'Customer Name', pinned: 'left' },
        { field: 'assetType', headerName: 'Asset Type' },
        { field: 'brand', headerName: 'Brand' },
        { field: 'model', headerName: 'Model' },
        { field: 'engine', headerName: 'Engine' },
        { field: 'chasis', headerName: 'Chassis' },
        { field: 'manufactureYear', headerName: 'YOM', cellDataType: 'number' },
        { field: 'registrationNo', headerName: 'REG No' },
        { field: 'letterOffer', headerName: 'Letter offer/FIS' },
        { field: 'hpNo', headerName: 'HP Agreement No.' },
        {
          field: 'principleStamp',
          headerName: 'Stamping Ref (Principle)'
        },
        {
          field: 'subsidiaryStamp',
          headerName: 'Stamping Ref (Subsidiary)'
        },
        { field: 'stampIsPaid', headerName: 'Stamping Paid', cellDataType: 'enumV1' },
        {
          field: 'applicantProductCost',
          headerName: 'Cost',
          cellDataType: 'currency',
          currency: 'RM'
        },
        {
          field: 'loanAmount',
          headerName: 'Loan Amount (RM)',
          cellDataType: 'currency',
          currency: 'RM'
        },
        { field: 'marginFinance', headerName: 'MOF', cellDataType: 'percentage' },
        {
          field: 'totalInterest',
          headerName: 'Total Interest',
          cellDataType: 'currency',
          currency: 'RM'
        },
        {
          field: 'totalPayable',
          headerName: 'Total Amount Payable',
          cellDataType: 'currency',
          currency: 'RM'
        },
        { field: 'fis', headerName: 'FIS', cellDataType: 'enumV1' },
        { field: 'rcOri', headerName: 'ORI RC', cellDataType: 'enumV1' },
        { field: 'insc', headerName: 'INSC', cellDataType: 'enumV1' },
        { field: 'insuranceStatus', headerName: 'Status' },
        { field: 'insuranceAgent', headerName: 'Insurance Agent' },
        { field: 'coverNote', headerName: 'Cover Note' },
        {
          field: 'insuranceStartDate',
          headerName: 'Insurance Start Date',
          filter: 'agDateColumnFilter',
          cellDataType: 'date'
        },
        {
          field: 'insuranceEndDate',
          headerName: 'Insurance End Date',
          filter: 'agDateColumnFilter',
          cellDataType: 'date'
        },
        {
          field: 'agreementExecution',
          headerName: 'Agreement Execution',
          filter: 'agDateColumnFilter',
          cellDataType: 'date'
        },
        {
          field: 'dueDate',
          headerName: 'Due Date',
          filter: 'agDateColumnFilter',
          cellDataType: 'date'
        },
        {
          field: 'monthlyInstallment',
          headerName: 'Monthly Repayment',
          cellDataType: 'currency',
          currency: 'RM'
        },
        { field: 'tenure', headerName: 'Tenure (Months)' },
        { field: 'interestRate', headerName: 'Interest Rate', cellDataType: 'percentage' },
        { field: 'prepayment', headerName: 'Advance & Prepaid' },
        { field: 'effectiveRate', headerName: 'Effective Yield', cellDataType: 'percentage' },
        {
          field: 'disbursementDate',
          headerName: 'Disbursement Date',
          filter: 'agDateColumnFilter',
          cellDataType: 'date'
        },
        { field: 'mor', headerName: 'Mode of Repayment' },
        {
          field: 'agreementIsReceived',
          headerName: 'Agreement Copy (Received)',
          cellDataType: 'enumV1'
        },
        {
          field: 'agreementIsDelivered',
          headerName: 'Agreement Copy (Delivered)',
          cellDataType: 'enumV1'
        },
        {
          field: 'stampingtIsDelivered',
          headerName: 'Stamping Certificate (Delivered)',
          cellDataType: 'enumV1'
        },
        {
          field: 'regCardIsDelivered',
          headerName: 'Registration Card (Delivered)',
          cellDataType: 'enumV1'
        },
        {
          field: 'disbursementNoticeIsDelivered',
          headerName: 'Disbursement Notice (Delivered)',
          cellDataType: 'enumV1'
        },
        {
          field: 'boardResolutionIsReceived',
          headerName: 'Board Resolution (Received)',
          cellDataType: 'enumV1'
        },
        { field: 'vehicleIsDischarged', headerName: 'Vehicle Discharged', cellDataType: 'enumV1' },
        { field: 'Salesman', headerName: 'Salesman' }
      ],
      selectedKey: 'All',
      selectedYear: new Date().getFullYear(),
      searchingList: new Map(),
      apiHeader: {},
      isEdit: false,
      pageSize: 10,
      averageInterest: 0,
      totalCost: 0,
      totalLoan: 0,
      filters: {
        name: {
          value: '',
          keys: []
        }
      },
      dateSelected: ''
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader']),
    ...mapState(['apiUrl']),
    uniqueYears() {
      // Extract unique years from the products
      return [
        ...new Set(
          this.masterList
            .map((product) => new Date(product.createdAt).getFullYear())
            .filter((year) => !isNaN(year)) // Filter out NaN values
        )
      ]
    },
    // availableYears() {
    //   const years = []
    //   const totalNumberofYears = 5
    //   for (let i = 0; i < totalNumberofYears; i++) {
    //     years.push(new Date().getFullYear() - i)
    //   }
    //   return years
    // },
    sortedValidYears() {
      // Sort the valid years in descending order
      return this.uniqueYears.slice().sort((a, b) => b - a)
    }
  },

  watch: {
    searchingList: {
      handler() {
        this.filterMasterlist()
      },
      deep: true
    },
    dateSelected(date) {
      if (!date) {
        this.resetDate()
        return
      }
      this.startDate = new Date(date[0]).toLocaleDateString('en-CA')
      this.endDate = new Date(date[1]).toLocaleDateString('en-CA')
      // this.searchingList.set('startDate', this.startDate)
      // this.searchingList.set('endDate', this.endDate)

      this.searchingList.set('createdAt', [
        JSON.stringify(this.startDate),
        JSON.stringify(this.endDate)
      ])
    },
    selectedYear(year) {
      let dateRange
      if (year < 0) {
        dateRange = [this.getDateMonthsBefore(parseInt(year)), this.getDateMonthsBefore(0)]
      } else {
        dateRange = [`${year}-01-01`, `${year}-12-31`]
      }
      this.dateSelected = [new Date(dateRange[0]), new Date(dateRange[1])]
      // this.fetchProductRanking(dateRange)
    }
  },

  mounted() {
    this.apiHeader = this.getAPIHeader()
    this.fetchData()
  },
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    formattedAmount,

    dateSort(a, b, isAsc) {
      const [day1, month1, year1] = this.getDateParts(a.createdAt)
      const [day2, month2, year2] = this.getDateParts(b.createdAt)
      const date1 = new Date(year1, month1, day1)
      const date2 = new Date(year2, month2, day2)

      if (isAsc == 1) return date2 - date1
      else return date1 - date2
    },

    getDateParts(date) {
      if (!date) return []
      const [day, month, year] = date.split('-')
      return [day, month - 1, year]
    },

    resetDate() {
      this.searchingList.delete('createdAt')
      this.startDate = null
      this.endDate = null
    },

    removeFilter(map) {
      // This work need to exist together with the watch dateSelected as the user can cancel / remove the date ranger from either the DatePicker or the Tags
      if (map[0] == 'createdAt') {
        this.resetDate()
      }
      this.searchingList.delete(map[0])
    },
    filterMasterlist() {
      // const year = this.selectedYear == 'All' ? null : this.selectedYear
      // const filterKey = this.selectedKey
      // const searchValue = this.filters.name.value
      this.filteredProducts = []
      this.averageInterest = this.calculateAverageInterest(this.masterList)

      // console.log(this.searchingList)
      // for (const [key, value] of this.searchingList.entries()) {
      //   console.log(`Key: ${key}, Value: ${value}`)
      // }
      const queryString = qs.stringify(Object.fromEntries(this.searchingList))

      // convert qs back to params object to mutate the createdAt array
      const urlParams = new URLSearchParams(queryString)
      if (this.startDate && this.endDate) {
        urlParams.set('createdAt', JSON.stringify(new Array(this.startDate, this.endDate)))
      }
      urlParams.delete('createdAt[0]')
      urlParams.delete('createdAt[1]')

      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/applicantProduct/all/`, {
          headers: this.apiHeader,
          params: urlParams
        })

        .then(({ data }) => {
          this.filteredProducts = data.ApplicantProducts.map((x) => ({
            ...x,
            hirerName: x.Hirer?.name,
            brand: x.Brand?.name,
            model: x.Product?.name,
            assetType: x.condition,
            registrationNo: x.registrationNo,
            manufactureYear: x.manufactureYear,
            marginFinance: x.marginFinance,
            boardResolutionIsReceived:
              x.boardResolutionIsReceived == '1' ? 'Yes' : x.boardResolutionIsReceived,
            applicantProductCost: x.cost,
            loanAmount: x.loanAmount,
            insuranceStartDate: this.formattedDate(x.insuranceStartDate),
            insuranceEndDate: this.formattedDate(x.insuranceStartDate),
            totalInterest: calTotalInterestRate(x.loanAmount, x.interestRate, x.tenure),
            totalPayable: calTotalPaylable(x.loanAmount, x.interestRate, x.tenure),
            monthlyInstallment: x.monthlyInstallment,
            tenure: x.tenure * 12,
            interestRate: x.interestRate,
            prepayment: x.prepayment ? JSON.parse(x.prepayment)?.join(' + ') : null,
            effectiveRate: x.effectiveRate,
            agreementExecution: this.formattedDate(x.agreementExecution),
            dueDate: this.formattedDate(x.dueDate),
            disbursementDate: this.formattedDate(x.disbursementDate),

            mor: x.Resource?.name ?? null,
            createdAt: this.formattedDate(x.createdAt)
          }))
          this.averageInterest = this.calculateAverageInterest(this.filteredProducts)
          this.totalCost = this.calculateTotalCost(this.filteredProducts)
          this.totalLoan = this.calculateTotalLoan(this.filteredProducts)
          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
          this.decreaseIsFetching()
        })

      // for (const dictionary of this.searchingList) {
      //   for (const [key, value] of Object.entries(dictionary)) {
      //     console.log(`Key: ${key}, Value: ${value}`)
      //   }
      // }

      // console.log(this.filteredProducts, 'ss')
    },

    getDateMonthsBefore(monthsBefore) {
      const currentDate = new Date()
      const targetDate = new Date(currentDate)
      targetDate.setMonth(currentDate.getMonth() + monthsBefore)
      return targetDate.toLocaleDateString('en-CA') // 'en-CA' for the "YYYY-MM-DD" format
    },
    calculateAverageInterest(products) {
      const totalInterest = products.reduce((acc, item) => {
        return acc + parseFloat(item.interestRate)
      }, 0)

      const averageInterest = totalInterest / products.length
      return isNaN(averageInterest) ? 0 : averageInterest // Return 0 if the result is NaN
    },

    calculateTotalCost(products) {
      const totalPayable = products.reduce((acc, item) => {
        return acc + parseFloat(undoFormatCurrency(item.cost) ?? 0)
      }, 0)

      return isNaN(totalPayable) ? 0 : totalPayable // Return 0 if the result is NaN
    },
    calculateTotalLoan(products) {
      const totalPayable = products.reduce((acc, item) => {
        return acc + parseFloat(undoFormatCurrency(item.loanAmount) ?? 0)
      }, 0)

      return isNaN(totalPayable) ? 0 : totalPayable // Return 0 if the result is NaN
    },
    setStartDate() {
      this.startDate = moment(new Date(new Date().setMonth(0))).format('YYYY-MM')
    },
    setEndDate() {
      this.endDate = moment(new Date()).format('YYYY-MM')
    },
    resetFilter() {
      this.setStartDate()
      this.setEndDate()
    },
    checkboxFilter() {
      const startDate = moment(this.startDate) // Replace with your start date
      const endDate = moment(this.endDate).add(1, 'months') // Replace with your end date
      this.filteredEvents = this.events.filter((item) => {
        const itemDate = moment(item.StartDate)
        return itemDate.isBetween(startDate, endDate, null, '[]') // '[]' includes start and end dates
      })
    },
    fetchData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/applicantProduct/all`, {
          headers: this.apiHeader
        })

        .then(({ data }) => {
          this.filters.name.keys = this.keys.map((x) => x.data)
          this.masterList = data.ApplicantProducts.map((x) => ({
            ...x,
            hirerName: x.Hirer?.name,
            brand: x.Brand?.name,
            model: x.Product?.name,
            assetType: x.condition,
            registrationNo: x.registrationNo,
            manufactureYear: x.manufactureYear,
            marginFinance: x.marginFinance,
            boardResolutionIsReceived:
              x.boardResolutionIsReceived == '1' ? 'Yes' : x.boardResolutionIsReceived,
            applicantProductCost: formattedAmount(x.cost),
            loanAmount: formattedAmount(x.loanAmount),
            insuranceStartDate: this.formattedDate(x.insuranceStartDate),
            insuranceEndDate: this.formattedDate(x.insuranceStartDate),
            totalInterest: calTotalInterestRate(x.loanAmount, x.interestRate, x.tenure),
            totalPayable: calTotalPaylable(x.loanAmount, x.interestRate, x.tenure),
            monthlyInstallment: formattedAmount(x.monthlyInstallment),
            tenure: x.tenure * 12,
            interestRate: x.interestRate,
            prepayment: x.prepayment ? JSON.parse(x.prepayment)?.join(' + ') : null,
            effectiveRate: x.effectiveRate,
            agreementExecution: this.formattedDate(x.agreementExecution),
            dueDate: this.formattedDate(x.dueDate),
            disbursementDate: this.formattedDate(x.disbursementDate),

            mor: x.Resource?.name ?? null,
            createdAt: this.formattedDate(x.createdAt)
          }))
          this.averageInterest = this.calculateAverageInterest(this.masterList)
          this.totalCost = formattedAmount(this.calculateTotalCost(this.masterList))
          this.totalLoan = formattedAmount(this.calculateTotalLoan(this.masterList))
          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
          this.decreaseIsFetching()
        })
    },
    getInsDate(s, e) {
      if (e) {
        return `${this.formattedDate(s)} - ${this.formattedDate(e)}`
      } else {
        return null
      }
    },
    formattedDate(date) {
      return date ? moment(date).utc().format('DD-MM-YYYY') : null
    },

    toLink(clickedRow) {
      const profileId = clickedRow?.id
      this.$router.push({
        name: 'MasterProfile',
        params: { id: profileId }
      })
    },
    addToSearch() {
      if (!this.filters.name.value) return
      this.searchingList.set(this.selectedKey, this.filters.name.value)
      this.filters.name.value = ''
    }
  }
}
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="table-controls row">
              <div class="col-md-10 col-lg-10 mb-2">
                <div class="d-lg-flex">
                  <div>
                    <h5 class="pb-1">Master List</h5>
                    <p>All Master List</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex-row">
              <div id="yearPicker" class="">
                <!-- <select id="year" v-model="selectedYear" class="form-select">
                  <option value="All">All years</option>
                  <option v-for="year in sortedValidYears" :key="year" :value="year">
                    {{ year }}
                  </option>
                </select> -->

                <div id="yearPicker" class="datepicker">
                  <!-- <Datepicker
                    v-model="dateSelected"
                    nable-time-picker="false"
                    is-range="true"
                    format="dd/MM/yyyy"
                    range
                  ></Datepicker> -->
                  <select v-model="selectedYear" class="form-select">
                    <option v-for="year in uniqueYears" :key="year" :value="year">
                      {{ year }}
                    </option>
                    <option value="-6">Past 6 Months</option>
                    <option value="-12">Past 12 Months</option>
                    <option value="-24">Past 24 Months</option>
                  </select>
                </div>
              </div>
              <div class="">
                <div class="flex-o">
                  <div class="flex-row gap-s">
                    <input
                      id="search"
                      v-model="filters.name.value"
                      class="form-control"
                      placeholder="Type to search..."
                    />
                    <select id="dropdown" v-model="selectedKey" class="form-select ml-2">
                      <option value="All">All</option>
                      <!-- <option v-for="key in keys" :key="key.id" :value="key.data">
                        {{ key.name }}
                      </option> -->
                      <option value="All">All</option>
                      <option value="hirerName">Customer Name</option>
                      <option value="assetType">Asset Type</option>
                      <option value="brand">Brand</option>
                      <option value="model">Model</option>
                      <option value="engine">Engine</option>
                      <option value="chasis">Chassis</option>
                      <option value="manufactureYear">YOM</option>
                      <option value="registrationNo">REG No</option>
                      <option value="letterOffer">Letter offer/FIS</option>
                      <option value="hpNo">HP Agreement No.</option>
                      <option value="principleStamp">Stamping Ref (Principle)</option>
                      <option value="subsidiaryStamp">Stamping Ref (Subsidiary)</option>
                      <option value="fis">FIS</option>
                      <option value="rcOri">ORI RC</option>
                      <option value="insc">INSC</option>
                      <option value="insuranceAgent">Insurance Agent</option>
                      <option value="coverNote">Cover Note</option>
                      <option value="tenure">Tenure (Months)</option>
                      <option value="mor">Mode of Repayment</option>
                      <option value="Salesman">Salesman</option>
                    </select>
                  </div>
                  <button v-for="map in searchingList" :key="map" class="tag">
                    {{ map[0] }}: {{ map[1] }}
                    <span class="cross" @click="removeFilter(map)">&#10006;</span>
                  </button>
                </div>
              </div>
              <button class="form-control filter-btn btn-primary" @click.prevent="addToSearch">
                Filter
              </button>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="px-4 mt-3">
              <div v-if="masterList.length > 0" class="table-responsive">
                <div class="combined-data">
                  <!-- Calculated interest -->
                  <div class="flex-col">
                    <div class="bold" col="3">
                      {{
                        filteredProducts.length > 0
                          ? 'Filtered Average Interest'
                          : 'Total Average Interest'
                      }}
                    </div>
                    <div>{{ averageInterest.toFixed(2) }}%</div>
                  </div>

                  <!-- Total Cost -->
                  <div class="flex-col">
                    <div class="bold" col="3">
                      {{ filteredProducts.length > 0 ? 'Filtered Total Cost' : 'Total Cost' }}
                    </div>
                    <div>RM {{ totalCost }}</div>
                  </div>

                  <!-- Total Loan -->
                  <div class="flex-col">
                    <div class="bold" col="3">
                      {{ filteredProducts.length > 0 ? 'Filtered Total Loan' : 'Total Loan' }}
                    </div>
                    <div>RM {{ totalLoan }}</div>
                  </div>

                  <!-- Amount of filtered item -->
                  <div class="flex-col">
                    <div class="bold" col="3">Entries</div>
                    <div>
                      {{ filteredProducts.length == 0 ? 'All' : filteredProducts.length }} of
                      {{ masterList.length }}
                    </div>
                  </div>
                </div>
                <AgGrid
                  :data="searchingList.size > 0 ? filteredProducts : masterList"
                  :keys="keys"
                  :searchable="false"
                  :exportable="false"
                  @onRowClicked="toLink"
                ></AgGrid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.flex-row {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.clickable {
  cursor: pointer;
  transition: 0.1s;
}
.clickable:hover {
  background-color: #aaaaaa;
}

.bold {
  font-weight: 600;
}

.gap-s {
  gap: 1rem;
}

button.tag {
  background-color: #e2e8f0;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  margin: 0.25rem 0.25rem 0.25rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 500;
  color: #4a5568;
  border: none;
  cursor: unset !important;
  display: inline-block;
}

button.tag > span {
  margin-left: 0.5rem;
  cursor: pointer;
}

.filter-btn {
  width: clamp(5rem, 10vw, 8rem);
}

.combined-data {
  display: flex;
  gap: 1rem;
}

.combined-data > .flex-col {
  border: 1px solid black;
  padding: 1rem;
}
</style>
