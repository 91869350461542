<template>
  <div class="p-3 multisteps-form__panel border-radius-xl" data-animation="FadeIn">
    <div class="card mt-4 px-3 py-4">
      <h5 class="font-weight-bolder my-3">Application Result</h5>
    </div>

    <div class="card mt-4 px-3 py-4">
      <div class="card-header font-weight-bolder my-3">
        <h5>Facility Information</h5>
      </div>
      <div class="card-body">
        <table v-if="applicationOverview.cost_of_equipment">
          <tr>
            <th class="border border-1">Cost of Equipment</th>

            <td class="border border-1">
              <ol v-if="applicationOverview.cost_of_equipment.length > 0" type="i">
                <template v-for="i in applicationOverview.cost_of_equipment" :key="i">
                  <li>{{ i }}</li>
                </template>
              </ol>
              <span v-else>N/A</span>
            </td>
          </tr>
          <tr>
            <th class="border border-1">Amount Finance</th>

            <td class="border border-1">
              <ol v-if="applicationOverview.amount_finance.length > 0" type="i">
                <template v-for="i in applicationOverview.amount_finance" :key="i">
                  <li>{{ i }}</li>
                </template>
              </ol>
              <span v-else>N/A</span>
            </td>
          </tr>
          <tr>
            <th class="border border-1">Interest Rate</th>

            <td class="border border-1">
              <p class="px-3">
                {{ applicationOverview.interest_rate ?? 'N/A' }}
              </p>
            </td>
          </tr>
          <tr>
            <th class="border border-1">Monthly payment</th>

            <td class="border border-1">
              <h6><u>Instalment per unit :</u></h6>
              <ol v-if="applicationOverview.monthly_payment.length > 0" type="i">
                <template v-for="i in applicationOverview.monthly_payment" :key="i">
                  <li>{{ i }}</li>
                </template>
              </ol>
              <span v-else>N/A</span>
            </td>
          </tr>

          <tr>
            <th class="border border-1">Prepayment</th>

            <td class="border border-1">
              <ol v-if="applicationOverview.prepayment.length > 0" type="i">
                <template v-for="i in applicationOverview.prepayment" :key="i">
                  <li>{{ i }}</li>
                </template>
              </ol>
              <span v-else>N/A</span>
            </td>
          </tr>
          <tr>
            <th class="border border-1">Insurance</th>

            <td class="border border-1">
              <h6><u>XCMG's panel insurer :</u></h6>
              <ol v-if="applicationOverview.insurance.length > 0" type="i">
                <template v-for="i in applicationOverview.insurance" :key="i">
                  <li>{{ i }}</li>
                </template>
              </ol>
              <span v-else>N/A</span>
            </td>
          </tr>
          <tr>
            <th class="border border-1">Security</th>

            <td class="border border-1">
              <ol v-if="applicationOverview.security.length > 0" type="i">
                <template v-for="i in applicationOverview.security" :key="i">
                  <li>{{ i }}</li>
                </template>
              </ol>
              <span v-else>N/A</span>
            </td>
          </tr>
          <tr>
            <th class="border border-1">Vendor</th>

            <td class="border border-1">
              <p class="px-3">{{ applicationOverview.vendor ?? 'N/A' }}</p>
            </td>
          </tr>
          <tr>
            <th class="border border-1">Asset</th>

            <td class="border border-1">
              <ol v-if="applicationOverview.asset.length > 0" type="i">
                <template v-for="i in applicationOverview.asset" :key="i">
                  <li>{{ i }}</li>
                </template>
              </ol>
              <span v-else>N/A</span>
            </td>
          </tr>
          <tr>
            <th class="border border-1">Purpose of Asset</th>

            <td class="border border-1">
              <p class="px-3">
                {{
                  applicationOverview.purpose && applicationOverview.purpose.length > 0
                    ? applicationOverview.purpose
                    : 'N/A'
                }}
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="card mt-4 px-3 py-4">
      <div class="card-header font-weight-bolder my-3"><h5>Score Card</h5></div>
      <div class="card-body">
        <table v-if="applicationFactors?.length > 0">
          <tr>
            <th class="border border-1 bg-main text-white">Sub Category</th>
            <th class="border border-1 bg-main text-white">Factor</th>
            <th class="border border-1 bg-main text-white">Description</th>
            <th class="border border-1 bg-main text-white">Weightage</th>
            <th class="border border-1 bg-main text-white">Score</th>
          </tr>
          <template v-for="f in applicationFactors" :key="f">
            <tr>
              <td class="border border-1">
                {{ f.Factor.Resource?.name }}
              </td>
              <td class="border border-1">
                {{ f.Factor?.name }}
              </td>
              <td class="border border-1">
                {{ f.Factor.description }}
              </td>
              <td class="border border-1">{{ f.Factor.weightage }}%</td>
              <td class="border border-1">
                {{ getFactorScore(f.Factor.FactorAttributes, f.score) }}
              </td>
            </tr>
          </template>
          <tr>
            <td class="border border-1" colspan="3"></td>
            <td class="border border-1">100%</td>
            <td class="border border-1">
              {{ calTotalFactorScore.toFixed(2) }}% ({{ application?.FinancialProgramTier?.name }})
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="card mt-4 px-3 py-4">
      <div class="card-header font-weight-bolder my-3">
        <h5>Reference Check</h5>
      </div>
      <div class="card-body">
        <table v-if="applicationCriterias?.length > 0" class="">
          <tr>
            <th class="border border-1 w-15 bg-main text-white">Criteria</th>
            <th class="border border-1 w-40 bg-main text-white">Description</th>
            <th class="border border-1 w-10 bg-main text-white">Y/N</th>
            <th class="border border-1 bg-main text-white">Remarks</th>
          </tr>
          <template v-for="criteria in applicationCriterias" :key="criteria">
            <tr>
              <td class="border border-1">
                {{ criteria.Criteria?.name }}
              </td>
              <td class="border border-1">
                {{ criteria.Criteria.description }}
              </td>
              <td class="border border-1">
                {{ criteria.status == 1 ? 'Yes' : 'No' }}
              </td>
              <td class="border border-1">
                {{ criteria.remark ?? 'N/A' }}
              </td>
            </tr>
          </template>
        </table>
      </div>
    </div>
    <div class="card mt-4 px-3 py-4">
      <div class="card-header font-weight-bolder my-3">
        <h5>Rationales & Recommendation</h5>
      </div>
      <div class="card-body">
        <table v-if="applicationRecommendations?.length > 0" class="border border-1">
          <tr>
            <th class="border border-1 bg-main text-white">No.</th>
            <th class="border border-1 bg-main text-white">Comment</th>
          </tr>
          <template v-for="(rcmd, index) in applicationRecommendations" :key="rcmd">
            <tr>
              <td>{{ index + 1 }}.</td>
              <td>
                {{ rcmd.comment }}
              </td>
            </tr>
          </template>
        </table>
        <div v-else>
          <h6>No Comments</h6>
        </div>
      </div>
    </div>
    <div class="card mt-4 px-3 py-4">
      <h5 class="font-weight-bolder my-3">Credit Information and Conduct of Account of Borrower</h5>
      <div class="table-responsive" v-if="Object.entries(creditInfo).length > 0">
        <table class="table">
          <tr class="border-bottom">
            <th>Month</th>
            <th>Withdrawal</th>
            <th>Deposit</th>
          </tr>
          <tr v-for="[key, value] in Object.entries(creditInfo)" :key="value">
            <td>{{ key }}</td>
            <td :class="value['Total Deposits'] < 0 ? 'text-danger' : ''">
              {{
                formattedAmount(value['Total Deposits'])
                  ? formattedAmount(value['Total Deposits'])
                  : formattedAmount(0)
              }}
            </td>
            <td :class="value['Total Deposits'] < 0 ? 'text-danger' : ''">
              {{
                formattedAmount(value['Total Withdrawals'])
                  ? formattedAmount(value['Total Withdrawals'])
                  : formattedAmount(0)
              }}
            </td>
          </tr>
        </table>
      </div>
      <div v-else>
        <h6 class="ms-2">No Data To Show</h6>
      </div>

      <br />
      <h6 class="font-weight-bolder my-3">Comments</h6>
      <textarea
        id="subject"
        v-model="creditInfoComment.value"
        class="form-control w-80"
        rows="5"
      ></textarea>
    </div>
    <div class="card mt-4 px-3 py-4">
      <h5 class="font-weight-bolder my-3">Financial Analysis of the Borrower</h5>
      <div class="w-100" v-if="Object.keys(financialAnalysis).length > 0">
        <table class="table w-100">
          <tr class="border-bottom">
            <th>Management Accounts as at FYE</th>
            <th class="text-wrap" v-for="key in Object.keys(financialAnalysis)" :key="key">
              {{ key.substring(7).trim().split(' ')[0] }}
            </th>
          </tr>
          <tr>
            <td>Turnover</td>
            <td
              v-for="value in Object.values(financialAnalysis)"
              :key="value"
              :class="value.turnover < 0 ? 'text-danger' : ''"
            >
              {{ formattedAmount(value.turnover) ?? formattedAmount(0) }}
            </td>
          </tr>
          <tr>
            <td>Profit Before Tax</td>
            <td
              v-for="value in Object.values(financialAnalysis)"
              :key="value"
              :class="value.PBT < 0 ? 'text-danger' : ''"
            >
              {{ formattedAmount(value.PBT) ?? formattedAmount(0) }}
            </td>
          </tr>
          <tr>
            <td>NPAT</td>
            <td
              v-for="value in Object.values(financialAnalysis)"
              :key="value"
              :class="value.NPAT < 0 ? 'text-danger' : ''"
            >
              {{ formattedAmount(value.NPAT) ?? formattedAmount(0) }}
            </td>
          </tr>
          <tr>
            <td>Net Worth (Tangible)</td>
            <td
              v-for="value in Object.values(financialAnalysis)"
              :key="value"
              :class="value.netWorth < 0 ? 'text-danger' : ''"
            >
              {{ formattedAmount(value.netWorth) ?? formattedAmount(0) }}
            </td>
          </tr>
          <tr>
            <td>Current Ratio (CA/CL)</td>
            <td
              v-for="value in Object.values(financialAnalysis)"
              :key="value"
              :class="value.currentRatio < 0 ? 'text-danger' : ''"
            >
              {{ formattedAmount(value.currentRatio) ?? formattedAmount(0) }}
            </td>
          </tr>
          <tr>
            <td>Gearing Ratio (TL/TNW)</td>
            <td
              v-for="value in Object.values(financialAnalysis)"
              :key="value"
              :class="value.gearingRatio < 0 ? 'text-danger' : ''"
            >
              {{ formattedAmount(value.gearingRatio) ?? formattedAmount(0) }}
            </td>
          </tr>
          <tr>
            <td>Leverage Ratio (TBB/TNW)</td>
            <td
              v-for="value in Object.values(financialAnalysis)"
              :key="value"
              :class="value.leverageRatio < 0 ? 'text-danger' : ''"
            >
              {{ formattedAmount(value.leverageRatio) ?? formattedAmount(0) }}
            </td>
          </tr>
          <tr>
            <td>Date FYE</td>
            <td v-for="value in Object.values(financialAnalysis)" :key="value">
              {{ value.dateFYE ?? 'N/A' }}
            </td>
          </tr>
          <tr>
            <td>File</td>
            <td v-for="value in Object.values(financialAnalysis)" :key="value">
              <a target="_blank" :href="value.pdfURL"><i class="far fa-file-pdf"></i></a>
            </td>
          </tr>
        </table>
      </div>
      <div v-else class="w-100">
        <h6 class="ms-3">No Data To Show</h6>
      </div>

      <br />
      <h6 class="font-weight-bolder my-3">Comments</h6>
      <textarea
        id="subject"
        v-model="financialComment.value"
        class="form-control w-80"
        rows="5"
      ></textarea>
    </div>

    <div class="card mt-4 px-3 py-4">
      <div class="col-5">
        <h5 class="font-weight-bolder my-3 flex-col">Document Checklist</h5>

        <h5>Mandatory</h5>

        <div class="input-wrapper">
          <input id="ctos" v-model="checklist.ctos" type="checkbox" name="ctos" />
          <label for="ctos">CTOS / CRRIS</label>
        </div>

        <div class="input-wrapper">
          <input id="nrics" v-model="checklist.nrics" type="checkbox" name="nrics" />
          <label for="nrics">Director & Shareholder NRIC</label>
        </div>

        <div class="input-wrapper">
          <input
            id="bankStatements"
            v-model="checklist.bankStatements"
            type="checkbox"
            name="bankStatements"
          />
          <label for="bankStatements">At least 3 months of bank statement</label>
        </div>

        <div class="input-wrapper">
          <input id="formD" v-model="checklist.formD" type="checkbox" name="formD" />
          <label for="formD">Business Registration Form (Form D)</label>
        </div>

        <br />
        <h5>Optional</h5>

        <div class="input-wrapper">
          <input
            id="financialStatements"
            v-model="checklist.financialStatements"
            type="checkbox"
            name="financialStatements"
          />
          <label for="financialStatements">Last 2 years financial statements</label>
        </div>

        <div class="input-wrapper">
          <input id="CPT" v-model="checklist.CPT" type="checkbox" name="CPT" />
          <label for="CPT">Contracts/ Purchase Orders/ Tender</label>
        </div>

        <div class="input-wrapper">
          <input
            id="projectsActivity"
            v-model="checklist.projectsActivity"
            type="checkbox"
            name="projectsActivity"
          />
          <label for="projectsActivity">List of completed/current and future projects</label>
        </div>

        <div class="input-wrapper">
          <input id="ageingList" v-model="checklist.ageingList" type="checkbox" name="ageingList" />
          <label for="ageingList">Debtor's and Creditor's Ageing List</label>
        </div>

        <div class="input-wrapper">
          <input id="memorandum" v-model="checklist.memorandum" type="checkbox" name="memorandum" />
          <label for="memorandum">Memorandum of Articles and Association</label>
        </div>

        <div class="input-wrapper">
          <input id="incorpCert" v-model="checklist.incorpCert" type="checkbox" name="incorpCert" />
          <label for="incorpCert">Incorporation Certificate</label>
        </div>

        <div class="input-wrapper">
          <input id="form9" v-model="checklist.form9" type="checkbox" name="form9" />
          <label for="form9">Form 9,24, 49, 44</label>
        </div>

        <div class="input-wrapper">
          <input
            id="proCertified"
            v-model="checklist.proCertified"
            type="checkbox"
            name="proCertified"
          />
          <label for="proCertified">Certificate issued by the Professional Bodies (if any)</label>
        </div>

        <div class="input-wrapper">
          <label for="clOthers">Others</label>
          <input id="clOthers" v-model="checklist.clOthers" type="text" name="clOthers" />
        </div>
      </div>
    </div>

    <div class="card mt-4 px-3 py-4">
      <div class="card-header font-weight-bolder my-3">
        <h5>Uploaded Documents</h5>
      </div>
      <div class="card-body">
        <table v-if="applicationDocuments?.length > 0">
          <tr>
            <th class="border border-1 bg-main text-white">Document type</th>
            <th class="border border-1 bg-main text-white">Path</th>
            <th class="border border-1 bg-main text-white">Uploaded Date</th>
          </tr>
          <template v-for="doc in applicationDocuments" :key="doc">
            <tr>
              <td class="border border-1">
                {{ doc.Resource?.name }}
              </td>
              <td class="border border-1">
                <a target="_blank" :href="doc.url"><i class="far fa-file-pdf fa-2x"></i></a>
              </td>
              <td class="border border-1">
                {{ formattedDate(doc.createdAt) }}
              </td>
            </tr>
          </template>
        </table>
      </div>
    </div>

    <div
      v-if="application?.Progress?.seq && application?.Progress?.seq <= 5"
      class="card mt-4 px-3 py-4"
    >
      <div class="mt-4 button-row d-flex col-12">
        <argon-button
          type="button"
          color="dark"
          variant="gradient"
          class="mb-0 ms-auto js-btn-next"
          title="Next"
          :disabled="isReadOnly"
          @click="submitForm()"
          >Submit</argon-button
        >
      </div>
    </div>
  </div>
</template>

<script>
//   import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from '@/components/ArgonButton.vue'
import { formattedAmount, undoFormatCurrency, calPercent } from '@/assets/js/calculator'
import { mapState, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import axios from 'axios'

export default {
  name: 'ScoreCard',
  components: {
    //   ArgonInput,
    ArgonButton
  },
  props: {
    application: {
      type: Object,
      default: () => {}
    },
    applicationDocuments: {
      type: Array,
      default: () => []
    },
    applicationFactors: {
      type: Array,
      default: () => []
    },
    applicationCriterias: {
      type: Array,
      default: () => []
    },
    applicationRecommendations: {
      type: Array,
      default: () => []
    },
    applicationComments: {
      type: Array,
      default: () => []
    },
    applicationOverview: {
      type: Object,
      default: () => {}
    },
    resources: {
      type: Array,
      default: () => []
    },
    stepLock: {
      type: Boolean,
      default: false
    }
  },
  emits: ['fetch-data', 'next-step'],
  data() {
    return {
      financialAnalysis: [],
      creditInfo: [],
      creditInfoComment: {
        value: '',
        id: ''
      },
      financialComment: {
        value: '',
        id: ''
      },
      debtorComment: {
        value: '',
        id: ''
      },
      checklist: {
        ctos: false,
        nrics: false,
        bankStatements: false,
        financialStatements: false,
        CPT: false,
        projectsActivity: false,
        ageingList: false,
        memorandum: false,
        incorpCert: false,
        form9: false,
        formD: false,
        proCertified: false,
        clOthers: ''
      }
    }
  },
  computed: {
    ...mapState(['isLoading', 'apiUrl', 'user']),
    ...mapGetters(['getAPIHeader']),
    calTotalFactorScore() {
      let totalFactorScore = 0
      this.applicationFactors.forEach((f) => {
        totalFactorScore += f.score * 10 * (parseInt(f.Factor.weightage) / 100)
      })
      return totalFactorScore
    }
  },

  watch: {
    applicationComments() {
      this.getCommentData()
    }
  },
  mounted() {
    this.apiHeader = this.getAPIHeader()
    this.getCommentData()
    this.getFinancialAnalysis()
    this.getCreditInfo()
    this.mapChecklist()
  },
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    formattedAmount,
    undoFormatCurrency,
    calPercent,

    mapChecklist() {
      if (this.application?.checklist) {
        const checklist = JSON.parse(this.application.checklist)
        if (checklist) {
          Object.keys(checklist).forEach((key) => {
            if (key === 'clOthers') {
              this.checklist[key] = checklist[key] ?? ''
            } else {
              this.checklist[key] = checklist[key]
            }
          })
        }
      }
    },
    getCreditInfo() {
      //Fetch Credit Info
      axios
        .get(`${this.apiUrl}/applicantDocument/credit_info/${this.application.id}`, {
          headers: this.getAPIHeader()
        })
        .then(({ data }) => {
          this.creditInfo = data
          for (const value of Object.values(this.creditInfo)) {
            for (const [key, innerValue] of Object.entries(value)) {
              value[key] = undoFormatCurrency(innerValue)
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getFinancialAnalysis() {
      //Fetch Financial Analysis
      axios
        .get(`${this.apiUrl}/applicantDocument/financial_analysis/${this.application.id}`, {
          headers: this.getAPIHeader()
        })
        .then(({ data }) => {
          this.financialAnalysis = data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getFactorScore(FactorAttributes, score) {
      const attr = FactorAttributes.find((x) => x.score == score)
      return attr?.score && attr?.name ? `${attr?.score} - ${attr?.name}` : 'N/A'
    },
    getCommentData() {
      if (this.applicationComments && this.applicationComments.length > 0) {
        this.applicationComments.forEach((x) => {
          this[x.category] = x
        })
      }
    },
    formattedDate(date) {
      return date ? moment(date).utc().format('DD-MM-YYYY hh:mm:ss') : null
    },
    validateChecklist(checklist) {
      // Add mandatory checklist here
      const manatoryList = ['ctos', 'nrics', 'bankStatements', 'formD']
      for (const key of manatoryList) {
        if (manatoryList.includes(key) && !checklist[key]) {
          return false
        }
      }
      return true
    },
    submitForm() {
      const valid = this.validateChecklist(this.checklist)
      if (!valid) {
        this.$swal.fire({
          title: 'Mandatory checklist is not completed!',
          icon: 'error',
          timer: 3000,
          timerProgressBar: true
        })
        return
      }
      this.increaseIsFetching()
      axios
        .put(
          `${this.apiUrl}/applicant/submit`,
          {
            id: this.application.id,
            checklist: this.checklist
          },
          {
            headers: this.apiHeader
          }
        )
        .then(() => {
          this.decreaseIsFetching()
          this.$emit('fetch-data')
          this.$emit('next-step')
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style>
td,
th {
  vertical-align: top;
  padding: 0.5rem 1rem;
}
.table > :not(:first-child) {
  border-top: 0px !important;
}
</style>
