<script>
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'ToHold',
  data() {
    return {
      months: 0,
      installmentId: null,
      filteredInstallments: []
    }
  },
  props: {
    installments: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader']),
    ...mapState(['apiUrl'])
  },
  watch: {
    installments: {
      handler(value) {
        this.filteredInstallments = value.filter((installment) => {
          return installment.status != 'Paid'
        })
      },
      deep: true
    }
  },
  methods: {
    holdCollection() {
      try {
        axios
          .post(
            `${this.apiUrl}/applicantProductReschedule`,
            {
              applicantProductId: this.$route.params.id,
              month: this.months,
              applicantProductTransactionId: this.selectedId
            },
            { headers: this.getAPIHeader() }
          )
          .then((res) => {
            const { status, msg } = res.data
            if (status == 0) {
              alert(msg)
            } else {
              alert('Reschedule request submitted, please wait for approval')
              this.$emit('invalidateData')
            }
          })
      } catch (error) {
        alert('An error occurred while putting collection on hold')
        console.log(error)
      }
    }
  }
}
</script>

<template>
  <div
    id="holdModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="holdModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="holdModal" class="modal-title fs-5">Put Collection On Hold</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="flex-col">
              <label for="months">Duration to add (Months)</label>
              <input v-model="months" name="months" id="months" type="number" />
            </div>

            <div class="flex-col">
              <label for="">Starting From</label>
              <select v-model="selectedId">
                <option
                  v-for="installment in filteredInstallments"
                  :key="installment.id"
                  :value="installment.id"
                >
                  {{ installment.description }}
                </option>
              </select>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            :disabled="filteredInstallments.length == 0"
            @click="holdCollection"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
