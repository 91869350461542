import Cookies from 'js-cookie'
import axios from 'axios'
import store from '../store'

export const AUTH_TOKEN_COOKIE_NAME = 'token'

export const isAuthenticated = async (deviceId) => {
  const token = Cookies.get(AUTH_TOKEN_COOKIE_NAME) // Assumes 'authToken' is the cookie name
  return !!token && (await isValidTokenForDevice(token, deviceId))
}
export const loginWithDevice = async (username, password) => {
  console.log(store.state.apiUrl, 'store.state.apiUrl')
  try {
    const { data } = await axios.post(`${store.state.apiUrl}/userAuth/login`, {
      username: username,
      password: password
    })
    if (data.status == 1) {
      const authToken = data.token // Assuming the server responds with a token
      Cookies.set(AUTH_TOKEN_COOKIE_NAME, authToken, {
        //secure: true,
        //httpOnly: true,
        sameSite: 'strict'
      })
    }
    return data
  } catch (error) {
    console.log(error)
    return { status: 0 } // Handle login failure
  }
}
export const validateUserPrivilegeLevel = async (level) => {
  if (store.state.privilegeLevel >= level) {
    return true
  }
  return false
}
export const logout = async () => {
  await Cookies.remove(AUTH_TOKEN_COOKIE_NAME)
}
const isValidTokenForDevice = async (token, deviceId) => {
  try {
    const { data } = await axios.get(`${store.state.apiUrl}/user/me`, {
      headers: {
        Authorization: `Bearer ${token} `,
        deviceId: deviceId
      }
    })

    if (data.User) {
      store.commit('setUser', data.User)
      return true
    }
    return false
  } catch (error) {
    console.log(error)
    return false
  }
}
