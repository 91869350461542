export function getUniqueIndex(params) {
  return parseInt(params.node.id) + 1
}

// Function to convert JSON to CSV
export function dataToCSV(data) {
  const headers = Object.keys(data[0])
  const csvRows = [headers.join(',')] // Header row

  data.forEach((row) => {
    const values = headers.map((header) => JSON.stringify(row[header])) // Escape commas inside values
    csvRows.push(values.join(','))
  })

  return csvRows.join('\n')
}

export function stackedDataToCSV(dataset, labels) {
  const columns = Object.keys(dataset) // ["Column A", "Column B"]

  // Create CSV rows starting with the header row
  const csvRows = [['Labels', ...columns].join(',')] // "Labels,Column A,Column B"

  // Loop through each label (row)
  labels.forEach((label, index) => {
    const row = [label] // Start the row with the label (e.g., "Row A")
    // Loop through each column to get the corresponding value for this row
    columns.forEach((column) => {
      row.push(dataset[column][index])
    })

    csvRows.push(row.join(',')) // Add the row to csvRows
  })

  return csvRows.join('\n')
}

// Function to download CSV file
export function downloadCSV(csvData, filename = 'data.csv') {
  const blob = new Blob([csvData], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)

  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('download', filename)
  a.click()

  // Clean up the URL object
  window.URL.revokeObjectURL(url)
}

export function downloadChartCsv(x, y, filename = 'data.csv') {
  const csvData = x.map((value, index) => `${value},${y[index]}`).join('\n')
  downloadCSV(csvData, filename)
}
