<template>
  <div class="card h-100">
    <div class="p-3 card-body">
      <div class="flex flex-row justify-end">
        <button
          v-if="exportable"
          class="btn btn-outline-primary"
          @click="downloadCSV(chartData.x, chartData.y)"
        >
          Export
        </button>
      </div>

      <h6>{{ chartTitle }}</h6>
      <div>
        <canvas :id="id" :ref="id" :width="width" :height="height"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Utils } from '@/assets/js/chart-util' // Assuming you have a utility file for months and numbers generation

export default {
  props: {
    id: {
      type: String,
      default: 'line-chart'
    },
    dataCount: {
      type: Number,
      default: 7
    },
    numberCfg: {
      type: Object,
      default: () => ({ count: 7, min: -100, max: 100 })
    },
    chartTitle: {
      type: String,
      default: ''
    },
    legendPosition: {
      type: String,
      default: 'top'
    },
    chartData: {
      type: Object,
      default: () => ({
        x: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        y: [65, 59, 80, 81, 56, 55, 40]
      })
    },
    width: {
      type: [String, Number],
      default: '100%'
    },
    height: {
      type: [String, Number],
      default: '100%'
    },
    // Extra options for the chart
    // currency: {predix: '$'}
    // dataLabels: {display: true}
    // scales: {x: {text: ''}, y: {text: ''}}
    // etc
    exportable: {
      type: Boolean,
      default: false
    },
    downloadCSV: {
      type: Function,
      default: () => {
        console.log('No download function provided')
      }
    },
    extraOption: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      handler(data) {
        if (!data.x && !data.y) return
        this.renderChart()
      },
      deep: true
    }
  },
  mounted() {},
  // beforeUnmount() {
  //   if (this.chart) {
  //     this.chart.destroy()
  //   }
  // },
  methods: {
    renderChart() {
      if (!this.$refs[this.id]) {
        return // Canvas not available yet, skip rendering
      }

      const labels = this.chartData.x
      const data = {
        labels: labels,
        datasets: [
          {
            // label: 'Dataset 1',
            data: this.chartData.y,
            borderColor: Utils.CHART_COLORS.red,
            backgroundColor: Utils.transparentize(Utils.CHART_COLORS.red, 0.5)
          }
        ]
      }

      const config = {
        type: 'line',
        data: data,
        options: {
          responsive: true,
          layout: {
            padding: {
              left: 30,
              right: 30,
              top: 30,
              bottom: 30
            }
          },
          plugins: {
            legend: {
              display: false,
              position: this.legendPosition
            },
            title: {
              display: false,
              text: this.chartTitle
            },
            datalabels: {
              display: this.extraOption?.dataLabels?.display ?? true,
              color: '#000',
              anchor: 'end',
              align: 'top',
              formatter: this.extraOption?.currency ? this.formatCurrency : this.defaultFormatter
            }
          },
          scales: {
            x: {
              display: true,

              title: {
                display: this.extraOption?.scales?.x?.text ? true : false,
                text: this.extraOption?.scales?.x?.text ?? 'Month'
              }
            },
            y: {
              display: true,
              title: {
                display: this.extraOption?.scales?.y?.text ? true : false,
                text: this.extraOption?.scales?.y?.text ?? 'Value'
              }
            }
          },
          onClick: (event, elements) => {
            if (elements.length > 0) {
              const clickedIndex = elements[0].index
              const clickedDataset = elements[0].datasetIndex
              // Mostly only need label
              const clickedLabel = this.chartData.x[clickedIndex]
              const clickedValue = this.chartData.y[clickedIndex]

              // Emit a custom event with relevant information
              this.$emit('dataClick', {
                index: clickedIndex,
                datasetIndex: clickedDataset,
                label: clickedLabel,
                value: clickedValue,
                title: this.chartTitle
              })
            }
          }
        },
        plugins: [ChartDataLabels]
      }
      Chart.defaults.font.weight = '600'

      let chartStatus = Chart.getChart(this.$refs[this.id])
      if (chartStatus != undefined) {
        chartStatus.destroy()
      }

      this.chart = new Chart(this.$refs[this.id].getContext('2d'), config)
    },
    formatCurrency(value) {
      // Try parsing the value into a float
      const floatValue = parseFloat(value)
      const prefix = this.extraOption.currency.prefix ?? '$'

      // Check if the parsed value is NaN
      if (isNaN(floatValue)) {
        // If NaN, return the original value
        return value
      }

      // Define magnitude abbreviations and corresponding thresholds
      const abbreviations = {
        B: 1e9, // Billion
        M: 1e6, // Million
        k: 1e3 // Thousand
        // Add more abbreviations if needed
      }

      // Find the appropriate abbreviation and threshold
      for (const abbreviation in abbreviations) {
        if (floatValue >= abbreviations[abbreviation]) {
          // Format the value with two decimal places
          const formattedValue = (floatValue / abbreviations[abbreviation]).toFixed(2)
          return `${prefix} ${formattedValue}${abbreviation}`
        }
      }

      // If the value is less than 1000, return the original value
      return `${prefix} ${floatValue.toFixed(2)}`
    },

    // the formatter function takes can take in both (value, context) or (value, index, values)
    defaultFormatter(value) {
      // Implement your default formatting logic here
      return value
    }
  }
}
</script>

<style scoped>
/* Add your component-specific styles here */
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.justify-end {
  justify-content: flex-end;
}
</style>
