<template>
  <div
    id="AddNewCompanyModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="AddNewCompanyModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="AddNewCompanyModalLabel" class="modal-title fs-5">Company</h1>
          <button
            ref="closeButton"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-6">
              <label class="form-label">Name</label>
              <input
                v-model="profileToUpdate.name"
                type="text"
                class="form-control col-8"
                :class="{ 'is-invalid': requiredKeys[0].invalid }"
              />
              <span class="text-danger" :class="{ 'd-none': !requiredKeys[0].invalid }">{{
                requiredKeys[0].text
              }}</span>
            </div>
            <div class="col-6">
              <label class="form-label">Registration No</label>
              <input
                v-model="profileToUpdate.registrationNo"
                type="text"
                class="form-control col-8"
                :class="{ 'is-invalid': requiredKeys[2].invalid }"
              />
              <span class="text-danger" :class="{ 'd-none': !requiredKeys[2].invalid }">{{
                requiredKeys[2].text
              }}</span>
            </div>
            <div class="col-6">
              <label class="form-label">Short Name</label>
              <input
                v-model="profileToUpdate.shortname"
                type="text"
                class="form-control col-8"
                :class="{ 'is-invalid': requiredKeys[3].invalid }"
              />
              <span class="text-danger" :class="{ 'd-none': !requiredKeys[3].invalid }">{{
                requiredKeys[3].text
              }}</span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <label class="form-label">Business Sector</label>
              <select
                id="sector-choices"
                v-model="profileToUpdate.resourceId"
                class="form-control"
                :class="{ 'is-invalid': requiredKeys[5].invalid }"
              >
                <option value="">Select Sector</option>
              </select>
              <span class="text-danger" :class="{ 'd-none': !requiredKeys[5].invalid }">{{
                requiredKeys[5].text
              }}</span>
            </div>
            <div class="col-6">
              <label class="form-label">Establish Year</label>
              <input
                v-model="profileToUpdate.establishYear"
                type="text"
                class="form-control col-8"
                :class="{ 'is-invalid': requiredKeys[4].invalid }"
              />
              <span class="text-danger" :class="{ 'd-none': !requiredKeys[4].invalid }">{{
                requiredKeys[4].text
              }}</span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <label class="form-label">Email</label>
              <input v-model="profileToUpdate.email" type="text" class="form-control col-8" />
            </div>
            <div class="col-6">
              <label class="form-label">Address</label>
              <input
                v-model="profileToUpdate.address"
                type="text"
                class="form-control col-8"
                :class="{ 'is-invalid': requiredKeys[1].invalid }"
              />
              <span class="text-danger" :class="{ 'd-none': !requiredKeys[1].invalid }">{{
                requiredKeys[1].text
              }}</span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <label class="form-label">Website</label>
              <input v-model="profileToUpdate.website" type="text" class="form-control col-8" />
            </div>
            <div class="col-6">
              <label class="form-label">Phone</label>
              <input v-model="profileToUpdate.phone" type="text" class="form-control col-8" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <label class="form-label">Person In Charge (PIC)</label>
              <input
                v-model="profileToUpdate.pic"
                type="text"
                class="form-control col-8"
                :class="{ 'is-invalid': requiredKeys[6].invalid }"
              />
              <span class="text-danger" :class="{ 'd-none': !requiredKeys[6].invalid }">{{
                requiredKeys[6].text
              }}</span>
            </div>
            <div class="col-6">
              <label class="form-label">PIC's Number</label>
              <input
                v-model="profileToUpdate.picNo"
                type="text"
                class="form-control col-8"
                :class="{ 'is-invalid': requiredKeys[7].invalid }"
              />
              <span class="text-danger" :class="{ 'd-none': !requiredKeys[7].invalid }">{{
                requiredKeys[7].text
              }}</span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-8">
              <label class="form-label">Remark</label>
              <textarea v-model="profileToUpdate.remark" class="form-control col-8" rows="3">
              </textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button
            type="button"
            class="btn btn-primary"
            :class="{ disabled: submitting }"
            @click="createCompanyProfile()"
          >
            <div v-if="submitting" class="spinner-border spinner-border-sm" role="status"></div>
            <span class="ms-1">
              {{ submitting ? 'Creating' : 'Create' }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import axios from 'axios'
import Choices from 'choices.js'

export default {
  name: 'AddNewCompanyModal',
  props: {},
  emits: ['fetch-data'],
  data() {
    return {
      id: '',
      token: '',
      resources: [],
      profileToUpdate: {
        resourceId: '',
        name: null,
        email: null,
        address: null,
        website: null,
        phone: null,
        remark: null,
        registrationNo: null,
        logoUrl: null,
        establishYear: null,
        shortname: null,
        pic: null,
        picNo: null
      },
      requiredKeys: [
        {
          // 0
          name: 'name',
          text: 'Please enter name',
          invalid: false,
          className: null
        },
        {
          // 1
          name: 'address',
          text: 'Please enter address',
          invalid: false,
          className: null
        },
        {
          // 2
          name: 'registrationNo',
          text: 'Please enter registration number',
          invalid: false,
          className: null
        },
        {
          // 3
          name: 'shortname',
          text: 'Please enter short name',
          invalid: false,
          className: null
        },
        {
          // 4
          name: 'establishYear',
          text: 'Please enter established year',
          invalid: false,
          className: null
        },
        {
          // 5
          name: 'resourceId',
          text: 'Please enter business sector',
          invalid: false,
          className: null
        },
        {
          // 6
          name: 'pic',
          text: 'Please enter person in charge',
          invalid: false,
          className: null
        },
        {
          // 7
          name: 'picNo',
          text: "Please enter person in charge's number",
          invalid: false,
          className: null
        }
      ],
      sectorChoices: {}
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader', 'formattedDate']),
    ...mapState(['apiUrl'])
  },

  mounted() {
    this.id = this.$route.params.id
    this.token = this.getAPIHeader()
    this.fetchResourceData()
  },

  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),

    createCompanyProfile() {
      if (this.validateForm()) {
        axios
          .post(`${this.apiUrl}/hirer/`, this.profileToUpdate, {
            headers: this.token
          })
          .then((res) => {
            console.log('RESPONSE RECEIVED: ', res.data)
            this.$emit('fetch-data')
            this.$swal
              .fire({
                title: 'Submitted Successfully',
                icon: 'success',
                timer: 2000,
                timerProgressBar: true
              })
              .then((click) => {
                this.$refs.closeButton.click()
                const { id } = res.data
                if ((click.isDismissed || click.isConfirmed) && id) {
                  this.$router.push({
                    name: 'CompanyProfile',
                    params: { id: id }
                  })
                  // window.location.href = `/company/profile/${id}`
                }
              })
          })
          .catch((err) => {
            console.log('AXIOS ERROR: ', err)
          })
      }
    },
    fetchResourceData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/resource/all`, {
          headers: this.token
        })
        .then(({ data }) => {
          this.resources = data.Resources
          this.buildResourceChoice()
          this.decreaseIsFetching()
        })
    },

    buildResourceChoice() {
      const choices = this.resources
        .filter((x) => x.category == 'HIRER_BUSINESS')
        .map((x) => ({
          label: x.name,
          value: x.id
        }))
      if (Object.keys(this.sectorChoices).length > 0) {
        this.sectorChoices.destroy()
      }
      const element = document.getElementById('sector-choices')
      if (element) {
        this.sectorChoices = new Choices(element, {
          searchEnabled: true,
          searchChoices: true,
          searchPlaceholderValue: 'Search here',
          choices: choices
        })
      }
    },

    validateForm() {
      console.log('Validating Applicant Form')
      let result = true
      this.requiredKeys.forEach((x, index) => {
        const value = this.profileToUpdate[x.name]
        console.log(value)
        if (this.validateValue(value)) {
          result = false
          this.requiredKeys[index].invalid = true
          if (this.requiredKeys[index].className != null) {
            const classes = document.getElementsByClassName(this.requiredKeys[index].className)
            if (classes.length > 0) {
              classes[0].classList.add('border-danger')
            }
          }
        } else {
          this.requiredKeys[index].invalid = false
          if (this.requiredKeys[index].className != null) {
            const classes = document.getElementsByClassName(this.requiredKeys[index].className)
            if (classes.length > 0) {
              classes[0].classList.remove('border-danger')
            }
          }
        }
      })
      return result
    }
  }
}
</script>

<style></style>
