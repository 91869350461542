<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row align-items-end">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Total Loan amount  (YTD)"
              :value="`RM ${formattedAmount(totalLoanAmount)}`"
              :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle'
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="quanterly Loan amount "
              :value="`RM ${formattedAmount(totalQuanterlyLoanAmount)}`"
              :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle'
              }"
            />
          </div>
          <div id="yearPicker" class="datepicker col-4">
            <!-- <Datepicker
              v-model="dateSelected"
              nable-time-picker="false"
              is-range="true"
              range
            ></Datepicker> -->
            <select v-model="selectedYear" class="form-select">
              <option v-for="year in availableYears" :key="year" :value="year">
                {{ year }}
              </option>
              <option value="-6">Past 6 Months</option>
              <option value="-12">Past 12 Months</option>
              <option value="-24">Past 24 Months</option>
            </select>
          </div>
        </div>
        <div class="row mt-3">
          <div v-if="Object.keys(overviewLoanData).length > 0" class="col-md-6">
            <div class="w-100">
              <thin-bar-chart
                id="overview-loan-chart"
                height="350"
                title="Overview Loan amount"
                :chart="{
                  labels: overviewLoanData.x,
                  datasets: {
                    data: overviewLoanData.y,
                    backgroundColor: getPwedColor(overviewLoanData.y)
                  },
                  currency: {
                    prefix: 'RM'
                  }
                }"
              />
            </div>
          </div>
          <div v-if="Object.keys(LeadData).length > 0" class="col-md-6">
            <stacked-bar-chart
              id="monthly-chart"
              title="Last 12 months Transactions"
              :chart="{
                labels: LeadData.x,
                datasets: [
                  {
                    label: 'Executed',
                    backgroundColor: '#5e72e4',
                    data: LeadData.y.map((x) => x.Executed.length)
                  },
                  {
                    label: 'Unexecuted',
                    backgroundColor: '#FF6600',
                    data: LeadData.y.map((x) => x.Unexecute.length)
                  }
                ]
              }"
            />
          </div>
        </div>
        <div class="row mt-3">
          <div v-if="Object.keys(productRankData).length > 0" class="col-md-6">
            <horizontal-bar-chart
              id="rank-product"
              height="350"
              title="Top 5 Model (YTD)"
              :chart="{
                labels: productRankData.x,
                datasets: {
                  data: productRankData.y,
                  backgroundColor: ['#5e72e4']
                }
              }"
            />
          </div>
          <div v-if="Object.keys(hirerRankData).length > 0" class="col-md-6">
            <horizontal-bar-chart
              id="rank-hirer"
              height="350"
              title="Top 5 Client (YTD)"
              :chart="{
                labels: hirerRankData.x,
                datasets: {
                  data: hirerRankData.y,
                  backgroundColor: ['#5e72e4']
                }
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { mapState, mapMutations, mapGetters } from 'vuex'
import MiniStatisticsCard from '../../examples/Cards/MiniStatisticsCard.vue'
import ThinBarChart from '@/examples/Charts/ThinBarChart.vue'
import StackedBarChart from '@/examples/Charts/StackedBarChart.vue'
import HorizontalBarChart from '@/examples/Charts/HorizontalBarChart.vue'
// import PieChart from "@/examples/Charts/PieChart.vue";
// import Datepicker from '@vuepic/vue-datepicker'

export default {
  name: 'DashboardDefault',
  components: {
    MiniStatisticsCard,
    StackedBarChart,
    HorizontalBarChart,
    // PieChart,
    ThinBarChart
    // Datepicker
  },
  data() {
    return {
      apiHeader: {},
      overviewLoanData: {},
      LeadData: {},
      productRankData: {},
      hirerRankData: {},
      totalLoanAmount: 0,
      totalQuanterlyLoanAmount: 0,
      queryParam: '',
      dateSelected: '',
      selectedYear: new Date().getFullYear()
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader']),
    ...mapState(['apiUrl', 'user']),
    availableYears() {
      const years = []
      const totalNumberofYears = 5
      for (let i = 0; i < totalNumberofYears; i++) {
        years.push(new Date().getFullYear() - i)
      }
      return years
    }
  },
  watch: {
    dateSelected(date) {
      this.fetchProductRanking(date)
      this.fetchHirerRanking(date)
      this.fetchTotalApproved(date)
      this.fetchOverviewLead(date)
    },
    selectedYear(year) {
      let dateRange
      if (year < 0) {
        dateRange = [this.getDateMonthsBefore(parseInt(year)), this.getDateMonthsBefore(0)]
      } else {
        dateRange = [`${year}-01-01`, `${year}-12-31`]
      }
      this.dateSelected = [new Date(dateRange[0]), new Date(dateRange[1])]
      // this.fetchProductRanking(dateRange)
    }
  },
  mounted() {
    this.apiHeader = this.getAPIHeader()
    this.fetchChartData()
  },
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    async fetchChartData() {
      this.fetchTotalApproved()
      this.fetchOverviewLead()
      this.fetchProductRanking()
      this.fetchHirerRanking()
    },
    fetchTotalApproved(dateRange) {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/analysis/total_approved_loan/${this.getDateQueryString(dateRange)}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          const month = data.monthly
          this.totalLoanAmount = data.total_loan
          this.totalQuanterlyLoanAmount = data.total_quarter_loan
          this.overviewLoanData = {
            y: Object.values(month).map((x) => {
              const result = x.reduce((acc, item) => {
                // Assuming each item in Overdue has properties toPay and payAmount
                const contribution = parseFloat(item.loanAmount)
                return acc + contribution
              }, 0)

              return result
            }),
            x: Object.keys(month)
          }
          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
          this.decreaseIsFetching()
        })
    },
    fetchOverviewLead(dateRange) {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/analysis/overview_lead/${this.getDateQueryString(dateRange)}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          const d = data.monthly
          this.LeadData = {
            y: Object.values(d),
            x: Object.keys(d)
          }
          // const d = data.monthly;
          // this.overviewLoanData = {
          //   y: Object.values(d),
          //   x: Object.keys(d),
          // };
          // this.pendingOrders = data;
          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
          this.decreaseIsFetching()
        })
    },
    fetchProductRanking(dateRange) {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/analysis/rank_product/${this.getDateQueryString(dateRange)}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.productRankData = {
            x: Object.keys(data),
            y: Object.values(data).map((x) => x.length)
          }
          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
          this.decreaseIsFetching()
        })
    },
    fetchHirerRanking(dateRange) {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/analysis/rank_hirer/${this.getDateQueryString(dateRange)}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.hirerRankData = {
            x: Object.keys(data),
            y: Object.values(data).map((x) => x.length)
          }
          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
          this.decreaseIsFetching()
        })
    },
    getDateQueryString(dateRange) {
      let queryString = ''
      const startDate = this.convertDate(dateRange?.[0])
      const endDate = this.convertDate(dateRange?.[1])

      if (startDate && endDate) {
        queryString = `?StartDate=${startDate}&EndDate=${endDate}`
      } else if (startDate) {
        queryString = `?StartDate=${startDate}`
      } else if (endDate) {
        queryString = `?EndDate=${endDate}`
      }
      return queryString
    },
    getPwedColor(array) {
      return array.map((x) => (x >= 5 ? (x <= 10 ? '#FF6600' : '#5e72e4') : '#f5365c'))
    },

    getDateMonthsBefore(monthsBefore) {
      const currentDate = new Date()
      const targetDate = new Date(currentDate)
      targetDate.setMonth(currentDate.getMonth() + monthsBefore)
      return targetDate.toLocaleDateString('en-CA') // 'en-CA' for the "YYYY-MM-DD" format
    },

    convertDate(date) {
      if (!date) {
        return
      }

      return new Date(date).toLocaleDateString('en-CA')
    },

    formattedAmount(amount) {
      if (!amount || isNaN(amount)) {
        return amount
      }

      const parts = amount.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return parts.join('.')
    }
  }
}
</script>

<style>
.datepicker {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  margin-left: auto;
}
</style>
