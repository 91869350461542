<template>
  <div class="card h-100">
    <div class="p-3 card-body">
      <h6>{{ title }}</h6>
      <div v-if="!exportable" class="flex-end flex-row">
        <button class="btn btn-outline-primary" @click="exportData(this.chart)">Export</button>
      </div>

      <div class="pt-3 chart">
        <canvas :id="id" class="chart-canvas" :height="height"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { stackedDataToCSV, downloadCSV } from '@/assets/js/general-func'
export default {
  name: 'StackedBarChart',
  props: {
    id: {
      type: String,
      default: 'stacked-bar-chart'
    },
    title: {
      type: String,
      default: 'Thin Bar Chart'
    },
    height: {
      type: [String, Number],
      default: '350'
    },
    chart: {
      type: Object,
      required: true,
      labels: Array,
      datasets: {
        type: Object,
        label: String,
        data: Array
      }
    },
    exportable: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    'chart.datasets.data': {
      handler() {
        this.buildChart()
      },
      deep: true
    }
  },
  mounted() {
    this.buildChart()
  },
  methods: {
    buildChart() {
      // Chart Consumption by day
      var ctx = document.getElementById(this.id).getContext('2d')

      let chartStatus = Chart.getChart(this.id)
      if (chartStatus != undefined) {
        chartStatus.destroy()
      }
      let datasets = this.chart.datasets.map((x) => {
        return {
          label: x.label,
          tension: 0.4,
          borderWidth: 0,
          borderRadius: 0,
          borderSkipped: false,
          backgroundColor: x.backgroundColor,
          data: x.data,
          maxBarThickness: 50
        }
      })
      new Chart(ctx, {
        type: 'bar',
        plugins: [ChartDataLabels],
        data: {
          labels: this.chart.labels,
          datasets: datasets
        },
        options: {
          layout: {
            padding: {
              top: 21
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              labels: { color: '#000000' }
            },
            datalabels: {
              color: '#000000',
              display: false,
              borderWidth: 1, // Border width for the data labels
              borderRadius: 4, // Border radius for the data labels
              padding: 2, // Padding around the text within the data labels
              formatter: function (value, context) {
                // Define a threshold for data value (you can adjust this as needed)
                var valueThreshold = 10000 // Set to the desired threshold

                // Check if the data value is greater than or equal to the threshold
                if (value >= valueThreshold) {
                  context.backgroundColor = 'white'
                  context.borderColor = 'white' // Border color for the data labels
                  return value // Return the label value to display it
                } else {
                  return '' // Return an empty string to hide the label
                }
              }
            },
            decimation: {
              enabled: false,
              algorithm: 'min-max'
            }
          },
          interaction: {
            intersect: false,
            mode: 'index'
          },

          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false
              },
              ticks: {
                display: false
              }
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false
              },
              ticks: {
                beginAtZero: true,
                font: {
                  size: 12,
                  family: 'Open Sans',
                  style: 'normal'
                },
                color: '#9ca2b7'
              }
            },
            // eslint-disable-next-line no-dupe-keys
            y: {
              stacked: true,
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5]
              },
              ticks: {
                display: true,
                padding: 10,
                color: '#9ca2b7'
              }
            },
            // eslint-disable-next-line no-dupe-keys
            x: {
              stacked: true,
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5]
              },
              ticks: {
                display: true,
                padding: 10,
                color: '#9ca2b7'
              }
            }
          },
          onClick: (event, elements) => {
            if (elements.length > 0) {
              const clickedIndex = elements[0].index
              const clickedDataset = elements[0].datasetIndex
              // Mostly only need label
              const clickedLabel = this.chart.labels[clickedIndex]

              const clickedValue = this.chart.datasets[clickedIndex]

              // Emit a custom event with relevant information
              this.$emit('dataClick', {
                index: clickedIndex,
                datasetIndex: clickedDataset,
                label: clickedLabel,
                value: clickedValue,
                title: this.chartTitle
              })
            }
          }
        }
      })
    },
    exportData(data) {
      const { labels, datasets } = data
      let transformedData = {}
      datasets.map((dataset) => {
        transformedData[dataset.label] = dataset.data
      })
      // console.log(transformedData)

      // console.log('Exporting data', data)
      // console.log(stackedDataToCSV(transformedData, labels))
      downloadCSV(stackedDataToCSV(transformedData, labels), 'collection-by-months.csv')
    }
  }
}
</script>
