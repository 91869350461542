<template>
  <div class="py-4 container-fluid">
    <div class="multisteps-form">
      <div id="profile" class="card card-body mt-4">
        <div class="multisteps-form__progress px-3 py-4">
          <template v-for="(step, index) in steps" :key="step">
            <button
              class="multisteps-form__progress-btn"
              type="button"
              title="Score Card"
              :class="[activeStep >= index ? activeClass : '']"
              @click="triggerStep(index)"
            >
              {{ step }}
            </button>
          </template>
        </div>
      </div>
      <div class="row mt-4">
        <form class="mb-8 multisteps-form__form">
          <ApplicationInfo
            :class="activeStep === 0 ? activeClass : 'd-none'"
            :resources="resources"
            :financial-programs="financialPrograms"
            :application-documents="applicationDocuments"
            :application="application"
            @fetch-data="fetchData"
            @next-step="nextStep"
            @fetch-documents="fetchDocuments"
          />

          <ScoreCard
            v-if="activeStep == 1"
            :class="activeStep === 1 ? activeClass : 'd-none'"
            :resources="resources"
            :application-factors="applicationFactors"
            :ctos-status="ctosStatus"
            @fetch-data="fetchData"
            @next-step="nextStep"
          />

          <ProductRequest
            v-if="activeStep == 2"
            :class="activeStep === 2 ? activeClass : 'd-none'"
            :resources="resources"
            :application="application"
            :application-products="applicationProducts"
            @fetch-data="fetchData"
            @next-step="nextStep"
          />

          <RuleBasedSRC
            v-if="activeStep == 3"
            :class="activeStep === 3 ? activeClass : 'd-none'"
            :resources="resources"
            :application-criterias="applicationCriterias"
            :application-recommendations="applicationRecommendations"
            :application-comments="applicationComments"
            :application="application"
            @fetch-data="fetchData"
            @next-step="nextStep"
          />

          <Result
            v-if="activeStep >= 4"
            :class="activeStep === 4 ? activeClass : 'd-none'"
            :application-documents="applicationDocuments"
            :resources="resources"
            :application-criterias="applicationCriterias"
            :application-factors="applicationFactors"
            :application-recommendations="applicationRecommendations"
            :application-comments="applicationComments"
            :application="application"
            :application-overview="applicationOverview"
            :step-lock="stepLock"
            @fetch-data="fetchData"
            @next-step="nextStep"
          />

          <Approval
            v-if="activeStep > 4"
            :class="activeStep >= 5 && activeStep < 6 ? activeClass : 'd-none'"
            :application="application"
            @fetch-data="fetchData"
          />

          <SignedDocument
            v-if="activeStep > 4"
            :class="activeStep >= 6 ? activeClass : 'd-none'"
            :application="application"
            :agreements="agreements"
            @fetch-data="fetchData"
          />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import ApplicationInfo from './components/ApplicationInfo.vue'
import ScoreCard from './components/ScoreCard.vue'
import ProductRequest from './components/ProductRequest.vue'
import RuleBasedSRC from './components/RuleBasedSRC.vue'
import Result from './components/Result.vue'
import Approval from './components/Approval.vue'
import SignedDocument from './components/SignedDocument.vue'
import axios from 'axios'
import '@vuepic/vue-datepicker/dist/main.css'
import setNavPills from '@/assets/js/nav-pills.js'

export default {
  name: 'SideNavItem',
  components: {
    ApplicationInfo,
    ScoreCard,
    ProductRequest,
    RuleBasedSRC,
    Result,
    Approval,
    SignedDocument
  },
  data() {
    return {
      //setting
      showMenu: false,
      activeClass: 'js-active position-relative',
      dangerClass: 'text-danger',
      sucessClass: 'text-success',
      activeStep: 0,
      formSteps: 0,
      currentProgress: 0,
      stepLock: false,
      steps: [
        'Basic Information',
        'Score Card',
        'Product',
        'SRC Report',
        'Result',
        'XCMG Approval',
        'Agreements'
      ],
      //value
      ctosStatus: false,
      resources: [],
      financialPrograms: [],
      application: {},
      applicationOverview: {},
      applicationCriterias: [],
      applicationFactors: [],
      applicationProducts: [],
      applicationRecommendations: [],
      applicationComments: [],
      agreements: [
        {
          label: 'Hire Purchase Agreement*',
          files: [],
          resource: 'HP Agreement'
        },
        {
          label: 'Guarantee and Indemnity of directors*',
          files: [],
          resource: 'Guarantee and Indemnity of directors'
        },
        {
          label: 'Second Schedule*',
          files: [],
          resource: 'Second Schedule'
        },
        {
          label: 'Offer Letter*',
          files: [],
          resource: 'Offer Letter'
        }
      ]
    }
  },
  computed: {
    ...mapState(['apiUrl', 'user']),
    ...mapGetters(['validateValue', 'getAPIHeader', 'capitalizeFirstLetter'])
  },
  beforeMount() {
    this.apiHeader = this.getAPIHeader()
    this.fetchResourceData()
    this.fetchFinancialProgramData()
    this.fetchDocuments()
    this.fetchApplicationFactorData()
    this.fetchApplicationCriteriaData()

    this.fetchApplicationRecommendationData()
    this.fetchApplicationCommentData()
  },
  mounted() {
    setNavPills()
    this.formSteps = this.steps.length
    if (this.$route.name == 'NewApplication') {
      this.application = {
        id: null,
        hirerId: null,
        financialProgramId: '',
        ApplicantGuarantors: []
      }
    } else {
      this.fetchData()
      //this.fetchApplicationProductData();
    }
  },
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    async fetchData() {
      this.fetchApplicationFactorData()
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/applicant/details/${this.$route.params.id}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.application = data.Applicant
          console.log(this.application, 'please be useful')

          this.fetchOverviewData()
          if (this.application.id) {
            this.currentProgress = this.application.Progress.seq - 1
            this.step = this.application.Progress.seq - 1
            this.stepLock = this.application.lockStatus
          }
          //get agreements and products
          this.fetchApplicationProductData()
          this.triggerStep(this.currentProgress)
          this.decreaseIsFetching()
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async fetchDocuments() {
      axios
        .get(`${this.apiUrl}/applicant/document/${this.$route.params.id}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.applicationDocuments = data.ApplicantDocuments
          // const resource = this.resources.find((x) => x.name == 'CTOS Report - Company')
          // const documentData = this.applicationDocuments?.find((x) => x.resourceId == resource.id)
          const documentData = this.applicationDocuments?.find(
            (x) => x?.Resource?.name == 'CTOS Report - Company'
          )

          // const documentData = this.applicationDocuments
          if (!documentData?.updatedAt) {
            this.ctosStatus = 'Pending'
          } else if (documentData?.updatedAt && documentData?.analyze_result) {
            this.ctosStatus = 'Completed'
          } else if (documentData?.updatedAt && !documentData?.analyze_result) {
            this.ctosStatus = 'Failed'
          }

          // this.ctosStatus =
          //   this.applicationDocuments?.find((x) => x.resourceId == resource.id)?.updatedAt ?? null
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async fetchApplicationProductData() {
      axios
        .get(`${this.apiUrl}/applicant/product/${this.$route.params.id}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.applicationProducts = data.ApplicantProducts
          for (let i = 0; i < this.agreements.length; i++) {
            this.agreements[i].files = []
          }
          this.applicationProducts.forEach((product) => {
            if (product.Agreements.length > 0) {
              product.Agreements[0].AgreementDocuments.forEach((agreement) => {
                this.agreements.forEach((a, i) => {
                  if (a.resource == agreement.Resource.name) {
                    this.agreements[i].files.push({
                      ...agreement,
                      product: product.Product,
                      brand: product.Brand
                    })
                  }
                })
              })
            }
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async fetchApplicationFactorData() {
      axios
        .get(`${this.apiUrl}/applicant/factor/${this.$route.params.id}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.applicationFactors = data.ApplicantFactors
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async fetchApplicationCriteriaData() {
      axios
        .get(`${this.apiUrl}/applicant/criteria/${this.$route.params.id}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.applicationCriterias = data.ApplicantCriterias
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async fetchApplicationRecommendationData() {
      axios
        .get(`${this.apiUrl}/applicant/recommendation/${this.$route.params.id}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.applicationRecommendations = data.ApplicantRecommendations
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async fetchApplicationCommentData() {
      axios
        .get(`${this.apiUrl}/applicant/comment/${this.$route.params.id}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.applicationComments = data.ApplicantComments
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async fetchOverviewData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/applicant/overview/${this.$route.params.id}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.applicationOverview = data
          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async fetchResourceData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/resource/all`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.resources = data.Resources
          this.decreaseIsFetching()
        })
    },
    async fetchFinancialProgramData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/financialProgram/all`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.financialPrograms = data.FinancialPrograms
          this.decreaseIsFetching()
        })
    },
    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.activeStep += 1
        this.fetchData()
      } else {
        this.activeStep -= 1
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1
      }
    },
    triggerStep(step) {
      if (step <= this.currentProgress) {
        if (this.currentProgress >= 5 && this.stepLock) {
          step > 3 ? (this.activeStep = step) : null
        } else {
          this.activeStep = step
        }
      }
    },
    deleteApplication() {
      axios
        .delete(`${this.apiUrl}/applicant`, {
          data: { id: this.applicationData.id },
          headers: this.apiHeader
        })
        .then((res) => {
          console.log('RESPONSE RECEIVED: ', res.data)
          this.$router.push({
            name: 'ApplicationList'
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<style>
.d-none {
  display: none;
}
</style>
