<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import EditProfileModal from './components/EditProfileModal.vue'
import { formattedAmount, calTotalInterestRate, calTotalPaylable } from '@/assets/js/calculator'
import { formattedDate, getDateDiff, getPaymentDateClass } from '@/assets/js/dateFunction'
export default {
  name: 'CompanyProfile',
  components: {
    EditProfileModal
  },
  data() {
    return {
      fetchedPic: {},
      applicationProduct: {},
      applicationAgreements: [],
      resources: [],
      transactions: [],
      inputKey: null,
      inputValue: null,
      inputType: 'text',
      inputLabel: null,
      inputSelections: []
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader']),
    ...mapState(['apiUrl'])
  },
  watch: {
    ['transactionSetting.selectedKey'](newKey) {
      this.transactionSetting.filters.name.keys = []
      if (newKey == 'All') {
        this.transactionSetting.filters.name.keys = this.transactionSetting.keys.map((x) => x.data)
      } else {
        this.transactionSetting.filters.name.keys.push(newKey)
      }
    }
  },
  beforeMount() {
    this.apiHeader = this.getAPIHeader()
    this.fetchData()
    this.fetchResourceData()
  },
  mounted() {},
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    formattedDate,
    formattedAmount,
    getResourceFileName(targetId) {
      const result = this.applicantDocumentTypes.find((documentType) => documentType.id == targetId)
      return result?.name
    },
    getResourceName(id) {
      if (!id) {
        return null
      }
      return this.resources.find((x) => x.id == id)?.name
    },
    renderPrepayment() {
      if (typeof this.applicationProduct.prepayment === 'string') {
        const parsedArray = JSON.parse(this.applicationProduct.prepayment)
        const resultArray = parsedArray.map(Number)
        this.applicationProduct.prepayment = resultArray
      }
    },
    fetchData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/applicantProduct/details/${this.$route.params.id}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.applicationProduct = data.ApplicantProduct

          this.transactions = this.applicationProduct.ApplicantProductTransactions.map((x) => ({
            ...x,
            chargeAmount: formattedAmount(x.chargeAmount),
            dueDate: formattedDate(x.dueDate),
            paidAt: formattedDate(x.paidAt),
            status: this.getpaymentStatus(x.dueDate, x.paidAt),
            statusClass: getPaymentDateClass(x.dueDate, x.paidAt)
          }))
          this.applicationProduct.totalInterest = calTotalInterestRate(
            this.applicationProduct.loanAmount,
            this.applicationProduct.interestRate,
            this.applicationProduct.tenure
          )
          this.applicationProduct.totalPayable = calTotalPaylable(
            this.applicationProduct.loanAmount,
            this.applicationProduct.interestRate,
            this.applicationProduct.tenure
          )
          this.renderPrepayment()
          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
          this.decreaseIsFetching()
        })
    },
    getpaymentStatus(endDate, startDate) {
      if (startDate) {
        const dayDiffs = getDateDiff(endDate, startDate)
        console.log(endDate, startDate, dayDiffs)
        if (dayDiffs) {
          return dayDiffs > 7 ? 'Fast' : dayDiffs < -7 ? 'Late' : 'Normal'
        } else {
          return 'Normal'
        }
      } else {
        return 'Pending'
      }
    },
    fetchResourceData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/resource/all`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.resources = data.Resources
          this.applicantDocumentTypes = this.resources.filter(
            (resource) =>
              resource.category === 'DOCUMENT_APPLICANT' ||
              resource.category === 'DOCUMENT_AGREEMENT'
          )
          this.decreaseIsFetching()
        })
    },
    setActiveTab(tab) {
      this.activeTab = tab
    },
    getDiffMonth(date) {
      return moment().diff(moment(date), 'months')
    },
    createLink() {
      this.$router.push({
        name: 'CompanyCreatePage'
      })
    },
    editable(label, key, type, resourceType, resourceCat) {
      this.inputLabel = label
      this.inputValue = this.applicationProduct[key]
      this.inputKey = key
      this.inputType = type
      console.log(this.inputLabel, this.inputValue, this.inputKey, this.inputType)
      if ((type == 'selection', resourceType == 'resource')) {
        this.inputSelections = this.getResouces(resourceCat)
      }
    },
    getResouces(category) {
      return this.resources
        .filter((x) => x.category == category)
        .map((x) => ({ ...x, value: x.id }))
    },
    changeStatus(key) {
      switch (this.applicationProduct[key]) {
        case null:
          this.applicationProduct[key] = 1
          break
        case 1:
          this.applicationProduct[key] = 0
          break
        case 0:
          this.applicationProduct[key] = null
          break
      }
      this.updateProfile(key)
    },
    updateProfile(key) {
      let payload = {
        id: this.applicationProduct.id
      }
      payload[key] = this.applicationProduct[key]
      axios
        .put(`${this.apiUrl}/applicantProduct/`, payload, {
          headers: this.apiHeader
        })
        .then(() => {})
        .catch((err) => {
          console.log('AXIOS ERROR: ', err)
        })
    }
  }
}
</script>
<template>
  <edit-profile-modal
    :id="applicationProduct.id"
    :input-key="inputKey"
    :value="inputValue"
    :input-label="inputLabel"
    :selections="inputSelections"
    :input-type="inputType"
    @fetch-data="fetchData"
  />
  <div class="py-4 container-fluid pt-0">
    <div class="card">
      <div class="card-header d-flex">
        <h5>Master Profile</h5>
      </div>
      <div class="card-body">
        <div v-if="applicationProduct.Hirer" id="customerInfo">
          <h6 class="w-50">Customer</h6>
          <div>
            <router-link
              :to="{
                name: 'CompanyProfile',
                params: { id: applicationProduct.Hirer.id }
              }"
              >{{ applicationProduct?.Hirer?.name ?? 'N/A' }}
              <span class="p-2 text-secondary"
                >( {{ applicationProduct.Hirer?.registrationNo ?? 'N/A' }} )</span
              ></router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-md-6">
        <div v-if="applicationProduct" class="card">
          <div class="card-header d-flex">
            <h6>Application Profile</h6>
            <router-link
              v-if="applicationProduct.applicantId"
              class="btn btn-outline-primary ms-auto"
              :to="{
                name: 'PendingApplication',
                params: { id: applicationProduct.applicantId }
              }"
              >View</router-link
            >
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <tr class="border-0">
                  <th class="text-capitalize">Date</th>

                  <td>{{ formattedDate(applicationProduct.createdAt) ?? 'N/A' }}</td>
                </tr>
                <tr class="border-0">
                  <th class="text-capitalize">Cost</th>

                  <td>RM {{ formattedAmount(applicationProduct.cost) ?? 'N/A' }}</td>
                </tr>
                <tr class="border-0">
                  <th class="text-capitalize">Loan Amount</th>

                  <td>RM {{ formattedAmount(applicationProduct.loanAmount) ?? 'N/A' }}</td>
                </tr>
                <tr class="border-0">
                  <th class="text-capitalize">Down Payment</th>
                  <td>RM {{ formattedAmount(applicationProduct.downpayment) ?? 'N/A' }}</td>
                </tr>
                <tr class="border-0">
                  <th class="text-capitalize">Margin of Finance</th>
                  <td>
                    {{
                      applicationProduct.marginFinance
                        ? applicationProduct.marginFinance + ' %'
                        : 'N/A'
                    }}
                  </td>
                </tr>

                <tr class="border-0">
                  <th class="text-capitalize">Total Interest</th>

                  <td>
                    RM {{ applicationProduct.totalInterest }} (
                    {{ applicationProduct.interestRate ?? 'N/A' }}
                    % )
                  </td>
                </tr>

                <tr class="border-0">
                  <th class="text-capitalize">Total Amount Payable</th>
                  <td>RM {{ formattedAmount(applicationProduct.totalAmountPayable) ?? 'N/A' }}</td>
                </tr>

                <tr class="border-0">
                  <th class="text-capitalize">Monthly Installment</th>
                  <td>
                    RM {{ formattedAmount(applicationProduct.monthlyInstallment) ?? 'N/A' }}
                    <span>
                      (
                      {{
                        applicationProduct.tenure ? parseInt(applicationProduct.tenure) * 12 : 'N/A'
                      }}
                      Months )
                    </span>
                  </td>
                </tr>
                <tr class="border-0">
                  <th class="text-capitalize">Prepayment</th>

                  <td>
                    {{
                      `${applicationProduct?.prepayment?.[0]} + ${applicationProduct?.prepayment?.[1]}`
                    }}
                  </td>
                </tr>

                <tr class="border-0">
                  <th class="text-capitalize">Effective Rate</th>

                  <td>
                    {{
                      applicationProduct.effectiveRate
                        ? applicationProduct.effectiveRate + '%'
                        : 'N/A'
                    }}
                  </td>
                </tr>
                <tr class="border-0">
                  <th class="text-capitalize">RC Ori</th>
                  <td class="editable-value border rounded-1" @click="changeStatus('rcOri')">
                    <span
                      class="badge"
                      :class="[
                        applicationProduct.rcOri != null
                          ? applicationProduct.rcOri
                            ? 'bg-success'
                            : 'bg-danger'
                          : 'bg-secondary'
                      ]"
                      >{{
                        applicationProduct.rcOri != null
                          ? applicationProduct.rcOri
                            ? 'Yes'
                            : 'No'
                          : 'Pending'
                      }}</span
                    >
                  </td>
                </tr>

                <tr class="border-0">
                  <th class="text-capitalize">INSC</th>
                  <td class="editable-value border rounded-1" @click="changeStatus('insc')">
                    <span
                      class="badge"
                      :class="[
                        applicationProduct.insc != null
                          ? applicationProduct.insc
                            ? 'bg-success'
                            : 'bg-danger'
                          : 'bg-secondary'
                      ]"
                      >{{
                        applicationProduct.insc != null
                          ? applicationProduct.insc
                            ? 'Yes'
                            : 'No'
                          : 'Pending'
                      }}</span
                    >
                  </td>
                </tr>

                <tr class="border-0">
                  <th class="text-capitalize">Disbursement Date</th>
                  <td
                    class="editable-value border rounded-1"
                    data-bs-toggle="modal"
                    data-bs-target="#EditProfileModal"
                    @click="editable('Disbursement Date', 'disbursementDate', 'date')"
                  >
                    {{ applicationProduct.disbursementDate ?? 'N/A' }}
                  </td>
                </tr>
                <tr class="border-0">
                  <th class="text-capitalize">Disbursement Notice Delivery Status</th>
                  <td
                    class="editable-value border rounded-1"
                    @click="changeStatus('disbursementNoticeIsDelivered')"
                  >
                    <span
                      class="badge"
                      :class="[
                        applicationProduct.disbursementNoticeIsDelivered != null
                          ? applicationProduct.disbursementNoticeIsDelivered
                            ? 'bg-success'
                            : 'bg-danger'
                          : 'bg-secondary'
                      ]"
                      >{{
                        applicationProduct.disbursementNoticeIsDelivered != null
                          ? applicationProduct.disbursementNoticeIsDelivered
                            ? 'Yes'
                            : 'No'
                          : 'Pending'
                      }}</span
                    >
                  </td>
                </tr>

                <tr class="border-0">
                  <th class="text-capitalize">Board Resolution Receipt Status</th>

                  <td
                    class="editable-value border rounded-1"
                    @click="changeStatus('boardResolutionIsReceived')"
                  >
                    <span
                      class="badge"
                      :class="[
                        applicationProduct.boardResolutionIsReceived != null
                          ? applicationProduct.boardResolutionIsReceived
                            ? 'bg-success'
                            : 'bg-danger'
                          : 'bg-secondary'
                      ]"
                      >{{
                        applicationProduct.boardResolutionIsReceived != null
                          ? applicationProduct.boardResolutionIsReceived
                            ? 'Yes'
                            : 'No'
                          : 'Pending'
                      }}</span
                    >
                  </td>
                </tr>
                <tr class="border-0">
                  <th class="text-capitalize">Salesman</th>

                  <td
                    class="editable-value border rounded-1"
                    data-bs-toggle="modal"
                    data-bs-target="#EditProfileModal"
                    @click="editable('Salesman', 'salesman', 'text')"
                  >
                    {{ applicationProduct.salesman ?? 'N/A' }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div id="insuranceInfo" class="card mt-3">
          <div class="card-header d-flex">
            <h6>Insurance</h6>
          </div>
          <div class="card-body table-responsive">
            <table class="table">
              <tr class="border-0">
                <th class="text-capitalize">Status</th>
                <td>
                  {{ applicationProduct.insuranceStatus ?? 'N/A' }}
                </td>
              </tr>
              <tr class="border-0">
                <th class="text-capitalize">Cover Note</th>
                <td
                  class="editable-value border rounded-1"
                  data-bs-toggle="modal"
                  data-bs-target="#EditProfileModal"
                  @click="editable('Cover Note', 'coverNote', 'text')"
                >
                  {{ applicationProduct.coverNote ?? 'N/A' }}
                </td>
              </tr>
              <tr class="border-0">
                <th class="text-capitalize">Insurance Agent</th>
                <td
                  class="editable-value border rounded-1"
                  data-bs-toggle="modal"
                  data-bs-target="#EditProfileModal"
                  @click="
                    editable(
                      'Insurance Agent',
                      'agentId',
                      'selection',
                      'resource',
                      'INSURANCE_AGENT'
                    )
                  "
                >
                  {{ getResourceName(applicationProduct.agentId) ?? 'N/A' }}
                </td>
              </tr>
              <tr class="border-0">
                <th class="text-capitalize">Insurance Rate</th>
                <td>{{ applicationProduct.insuranceRate ?? 'N/A' }} %</td>
              </tr>
              <tr class="border-0">
                <th class="text-capitalize">Insurance Start Date</th>
                <td
                  class="editable-value border rounded-1"
                  data-bs-toggle="modal"
                  data-bs-target="#EditProfileModal"
                  @click="editable('Insurance Start Date', 'insuranceStartDate', 'date')"
                >
                  {{ formattedDate(applicationProduct.insuranceStartDate) ?? 'N/A' }}
                </td>
              </tr>
              <tr class="border-0">
                <th class="text-capitalize">Insurance End Date</th>
                <td
                  class="editable-value border rounded-1"
                  data-bs-toggle="modal"
                  data-bs-target="#EditProfileModal"
                  @click="editable('Insurance End Date', 'insuranceEndDate', 'date')"
                >
                  {{ formattedDate(applicationProduct.insuranceEndDate) ?? 'N/A' }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div v-if="applicationProduct.Product" id="productInfo" class="card">
          <div class="card-header d-flex">
            <h6>Product Profile</h6>
          </div>

          <div class="card-body table-responsive">
            <table class="table">
              <tr class="border-0">
                <th class="text-capitalize">name</th>
                <td class="text-secondary">
                  <router-link
                    class="me-2"
                    :to="{
                      name: 'ProductProfile',
                      params: { id: applicationProduct.Product.id }
                    }"
                    >{{ applicationProduct?.Product?.name ?? 'N/A' }}</router-link
                  >
                  <router-link
                    :to="{
                      name: 'TypeProfile',
                      params: { id: applicationProduct.Brand.id }
                    }"
                    >( {{ applicationProduct?.Brand?.name ?? 'N/A' }} )</router-link
                  >
                </td>
              </tr>
              <tr class="border-0">
                <th class="text-capitalize">Condition</th>
                <td class="px-4">
                  {{
                    applicationProduct.isNew === null
                      ? 'N/A'
                      : applicationProduct.isNew === 1
                      ? 'New'
                      : 'Used'
                  }}
                </td>
              </tr>

              <tr class="border-0">
                <th class="text-capitalize">Engine</th>
                <td
                  class="px-4 editable-value border rounded-1"
                  data-bs-toggle="modal"
                  data-bs-target="#EditProfileModal"
                  @click="editable('Engine', 'engine', 'text')"
                >
                  {{ applicationProduct.engine ?? 'N/A' }}
                </td>
              </tr>
              <tr class="border-0">
                <th class="text-capitalize">Chasis</th>
                <td
                  class="editable-value border rounded-1 px-4"
                  data-bs-toggle="modal"
                  data-bs-target="#EditProfileModal"
                  @click="editable('Chasis', 'chasis', 'text')"
                >
                  {{ applicationProduct.chasis ?? 'N/A' }}
                </td>
              </tr>
              <tr class="border-0">
                <th class="text-capitalize">Year of Manufacture</th>

                <td
                  class="editable-value border rounded-1 px-4"
                  data-bs-toggle="modal"
                  data-bs-target="#EditProfileModal"
                  @click="editable('manufactureYear', 'manufactureYear', 'text')"
                >
                  {{ applicationProduct.manufactureYear ?? 'N/A' }}
                </td>
              </tr>
              <tr class="border-0">
                <th class="text-capitalize">Registration Card No</th>

                <td
                  class="editable-value border rounded-1 px-4"
                  data-bs-toggle="modal"
                  data-bs-target="#EditProfileModal"
                  @click="editable('Registration Card No', 'registrationNo', 'text')"
                >
                  {{ applicationProduct.registrationNo ?? 'N/A' }}
                </td>
              </tr>
              <tr class="border-0">
                <th class="text-capitalize">Registration Card Delivery Status</th>
                <td
                  class="editable-value border rounded-1 px-4"
                  @click="changeStatus('regCardIsDelivered')"
                >
                  <span
                    class="badge"
                    :class="[
                      applicationProduct.regCardIsDelivered != null
                        ? applicationProduct.regCardIsDelivered
                          ? 'bg-success'
                          : 'bg-danger'
                        : 'bg-secondary'
                    ]"
                    >{{
                      applicationProduct.regCardIsDelivered != null
                        ? applicationProduct.regCardIsDelivered
                          ? 'Yes'
                          : 'No'
                        : 'Pending'
                    }}</span
                  >
                </td>
              </tr>
              <tr class="border-0">
                <th class="text-capitalize">Vehicle Discharge Status</th>
                <td
                  class="editable-value border rounded-1 px-4"
                  @click="changeStatus('vehicleIsDischarged')"
                >
                  <span
                    class="badge"
                    :class="[
                      applicationProduct.vehicleIsDischarged != null
                        ? applicationProduct.vehicleIsDischarged
                          ? 'bg-success'
                          : 'bg-danger'
                        : 'bg-secondary'
                    ]"
                    >{{
                      applicationProduct.vehicleIsDischarged != null
                        ? applicationProduct.vehicleIsDischarged
                          ? 'Yes'
                          : 'No'
                        : 'Pending'
                    }}</span
                  >
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div id="AgreementInfo" class="card mt-3">
          <div class="card-header d-flex">
            <h6>Agreement</h6>
          </div>
          <div class="card-body table-responsive">
            <table class="table">
              <tr class="border-0">
                <th class="text-capitalize">letter Offer</th>
                <td>{{ applicationProduct.letterOffer ?? 'N/A' }}</td>
              </tr>
              <tr class="border-0">
                <th class="text-capitalize">Hire Purchase Agreement</th>
                <td>{{ applicationProduct.hpNo ?? 'N/A' }}</td>
              </tr>
              <tr class="border-0">
                <th class="text-capitalize">FIS</th>

                <td class="editable-value border rounded-1" @click="changeStatus('fis')">
                  <span
                    class="badge"
                    :class="[
                      applicationProduct.fis != null
                        ? applicationProduct.fis
                          ? 'bg-success'
                          : 'bg-danger'
                        : 'bg-secondary'
                    ]"
                    >{{
                      applicationProduct.fis != null
                        ? applicationProduct.fis
                          ? 'Yes'
                          : 'No'
                        : 'Pending'
                    }}</span
                  >
                </td>
              </tr>

              <tr class="border-0">
                <th class="text-capitalize">Stamping Ref (Principle)</th>

                <td
                  class="editable-value border rounded-1"
                  data-bs-toggle="modal"
                  data-bs-target="#EditProfileModal"
                  @click="editable('Stamping Ref (Principle)', 'principleStamp', 'text')"
                >
                  {{ applicationProduct.principleStamp ?? 'N/A' }}
                </td>
              </tr>

              <tr class="border-0">
                <th class="text-capitalize">Stamping Ref (Subsidiary)</th>
                <td
                  class="editable-value border rounded-1"
                  data-bs-toggle="modal"
                  data-bs-target="#EditProfileModal"
                  @click="editable('Stamping Ref (Subsidiary)', 'subsidiaryStamp', 'text')"
                >
                  {{ applicationProduct.subsidiaryStamp ?? 'N/A' }}
                </td>
              </tr>

              <tr class="border-0">
                <th class="text-capitalize">Stamping Paid</th>

                <td class="editable-value border rounded-1" @click="changeStatus('stampIsPaid')">
                  <span
                    class="badge"
                    :class="[
                      applicationProduct.stampIsPaid != null
                        ? applicationProduct.stampIsPaid
                          ? 'bg-success'
                          : 'bg-danger'
                        : 'bg-secondary'
                    ]"
                    >{{
                      applicationProduct.stampIsPaid != null
                        ? applicationProduct.stampIsPaid
                          ? 'Yes'
                          : 'No'
                        : 'Pending'
                    }}</span
                  >
                </td>
              </tr>
              <tr class="border-0">
                <th class="text-capitalize">Stamping Certificate Delivery Status</th>

                <td
                  class="editable-value border rounded-1"
                  @click="changeStatus('stampingIsDelivered')"
                >
                  <span
                    class="badge"
                    :class="[
                      applicationProduct.stampingIsDelivered != null
                        ? applicationProduct.stampingIsDelivered
                          ? 'bg-success'
                          : 'bg-danger'
                        : 'bg-secondary'
                    ]"
                    >{{
                      applicationProduct.stampingIsDelivered != null
                        ? applicationProduct.stampingIsDelivered
                          ? 'Yes'
                          : 'No'
                        : 'Pending'
                    }}</span
                  >
                </td>
              </tr>

              <tr class="border-0">
                <th class="text-capitalize">Agreement Execution</th>

                <td
                  class="editable-value border rounded-1"
                  data-bs-toggle="modal"
                  data-bs-target="#EditProfileModal"
                  @click="editable('Agreement Execution', 'agreementExecution', 'date')"
                >
                  {{ formattedDate(applicationProduct.agreementExecution) ?? 'N/A' }}
                </td>
              </tr>
              <tr class="border-0">
                <th class="text-capitalize">Agreement Copy Delivered Status</th>

                <td
                  class="editable-value border rounded-1"
                  @click="changeStatus('agreementIsDelivered')"
                >
                  <span
                    class="badge"
                    :class="[
                      applicationProduct.agreementIsDelivered != null
                        ? applicationProduct.agreementIsDelivered
                          ? 'bg-success'
                          : 'bg-danger'
                        : 'bg-secondary'
                    ]"
                    >{{
                      applicationProduct.agreementIsDelivered != null
                        ? applicationProduct.agreementIsDelivered
                          ? 'Yes'
                          : 'No'
                        : 'Pending'
                    }}</span
                  >
                </td>
              </tr>

              <tr class="border-0">
                <th class="text-capitalize">Agreement Copy Received Status</th>

                <td
                  class="editable-value border rounded-1"
                  @click="changeStatus('agreementIsReceived')"
                >
                  <span
                    class="badge"
                    :class="[
                      applicationProduct.agreementIsReceived != null
                        ? applicationProduct.agreementIsReceived
                          ? 'bg-success'
                          : 'bg-danger'
                        : 'bg-secondary'
                    ]"
                    >{{
                      applicationProduct.agreementIsReceived != null
                        ? applicationProduct.agreementIsReceived
                          ? 'Yes'
                          : 'No'
                        : 'Pending'
                    }}</span
                  >
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="card mt-3">
          <div class="card-header">
            <p>
              <strong>Transactions</strong>
            </p>

            <router-link :to="{ name: 'CollectionProfile', params: { id: this.$route.params.id } }"
              >Collection Profile</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.collapse-container {
  cursor: pointer;
  border-radius: 20px;
}
.editable-value:hover {
  cursor: pointer;
  font-weight: bold;
  transition: 0.1s;
}
</style>
